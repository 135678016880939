import axiosBase from "./base";

export const getStudentsBatch = (batchID) => {
    return new Promise((res, rej)=>{
        axiosBase("get", `admin/batch/${batchID}`).then((e)=>{
            res(e)
        }).catch((e)=>{
            rej(e)
        })
    })
}

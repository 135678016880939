import { message } from "antd";
import { initializeApp } from "firebase/app";
import {addDoc, collection, doc, getDoc, getFirestore} from 'firebase/firestore'
import { getAuth,  onAuthStateChanged, sendSignInLinkToEmail, signOut } from 'firebase/auth'
const firebaseConfig = {
  apiKey: "AIzaSyBX-T6US5-bwZgPLIZbsYJYDnoXFSKujBk",
  authDomain: "aim-genius.firebaseapp.com",
  projectId: "aim-genius",
  storageBucket: "aim-genius.appspot.com",
  messagingSenderId: "381285941734",
  appId: "1:381285941734:web:88876ab729ae24caefd627"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app)
export const auth = getAuth(app)





export const StudentLogin = (email) => {
    return new Promise((en, ek)=>{
        sendSignInLinkToEmail(auth, email)
        // signInWithEmailLink(auth, email, "http://localhost:3000/auth_")
        .then((result) => {
          en(result)
        })
        .catch((error) => {
          console.log(error)
        });
    })
}


export const logout = () =>{ 
    signOut(auth).then((e)=>{
        window.location = "./"
    }).catch((e)=>{
        message.error("Failed to logout")
    })
} 
export const checkAuth = () => {
    return new Promise((en, ek)=>{
        onAuthStateChanged(auth, (e)=>{
            if(e){
                en(e)
            }else{
                ek(null)
            }
        })
    }) 
}




export const getLeaderboard = async () => {
    try {
      const leaderboardDoc = doc(db, "AppData", "leaderboard");
      const docSnapshot = await getDoc(leaderboardDoc);
  
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        return data || [];
      } else {
        console.error("No leaderboard data found.");
        return [];
      }
    } catch (error) {
      console.error("Error fetching leaderboard:", error);
      throw error;
    }
  };

  export const selfStudentRegister = async (data) => {
    try {
      const srgd = collection(db, "StudentRegistration"); // Reference the 'StudentRegistration' collection
      const docSnapshot = await addDoc(srgd, data); // Add a new document with the 'data' object
  
      // Handle success (optional):
      return(docSnapshot.id);
      // You might want to redirect the user, show a success message, or perform other actions here.
  
    } catch (error) {
      // Handle errors:
      return(error.errorCode)
      message.error("Error registering")
      // You might want to display an error message to the user or log the error for debugging.
    }
  };
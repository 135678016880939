import { Select, Input } from 'antd';
import { useState } from 'react';

const { Option } = Select;

const PaymentCard = ({ studentData, paymentIcon, handlePayment }) => {
  const [selectedInstallment, setSelectedInstallment] = useState(null);
  const [amountToPay, setAmountToPay] = useState('');

  // Filter unpaid installments
  const unpaidInstallments = studentData.payments.filter(
    (payment) => payment.amount < payment.expectedAmount
  );

  const handleNextClick = () => {
    if (selectedInstallment && amountToPay > 0) {
      handlePayment(selectedInstallment, Number(amountToPay));
    } else {
      alert('Please select an installment and enter a valid amount.');
    }
  };

  return (
    <div className="bg-white p-3 rounded-xl shadow-xl flex items-center justify-between mt-4">
      <div className="flex space-x-6 items-center">
        <img src={paymentIcon} className="w-auto h-24 rounded-lg" alt="Payment" />
        <div>
          <p className="font-semibold text-base">Pay Installment</p>
          <p className="font-semibold text-sm text-gray-400">
            Pay your Installment via bKash
          </p>
          <div className="mt-4">
            {/* Select unpaid installments */}
            <Select
              className='w-full'
              placeholder="Select Installment"
              onChange={(value) => {
                const installment = unpaidInstallments.find(
                  (inst) => inst.installmentNumber === value
                );
                setSelectedInstallment(value);
                setAmountToPay(installment ? installment.expectedAmount - installment.amount : '');
              }}
            >
              {unpaidInstallments.map((installment) => (
                <Option
                  key={installment.installmentNumber}
                  value={installment.installmentNumber}
                >
                  Installment {installment.installmentNumber} - Due: $
                  {installment.expectedAmount - installment.amount}
                </Option>
              ))}
            </Select>

            {/* Input for amount to pay */}
            <Input
              className="mt-2"
              type="number"
              placeholder="Enter amount to pay"
              value={amountToPay}
              onChange={(e) => setAmountToPay(e.target.value)}
              max={
                selectedInstallment
                  ? unpaidInstallments.find(
                      (inst) => inst.installmentNumber === selectedInstallment
                    ).expectedAmount -
                    unpaidInstallments.find(
                      (inst) => inst.installmentNumber === selectedInstallment
                    ).amount
                  : ''
              }
              min="1"
            />
          </div>
        </div>
      </div>
      <div className="flex space-x-2 items-center">
        <div
          className="bg-gray-300 rounded-md p-2 flex items-center cursor-pointer"
          onClick={handleNextClick}
        >
          <i className="fas fa-chevron-right fa-sm text-black" />
        </div>
      </div>
    </div>
  );
};

export default PaymentCard;

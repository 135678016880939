import { useEffect, useState } from "react"
import { checkAuth } from "../../helpers/firebase"
import { searchStudent } from "../../helpers/requests/student"
import { getStudentsBatch } from "../../helpers/requests/batch"
import paymentIcon  from './payment.png'
import paidIcon  from './paid.png'
import unpaidIcon  from './unpaid.png'
import PaymentCard from "./Components/paymentCard"
import jsPDF from 'jspdf'
import "jspdf-autotable"; // Import the AutoTable plugin
import { QRCode } from "antd"


const Dashboard = () => {

    const [studentData, setStudentData] = useState({payments:[]})
    const [batchData, setBatchData] = useState({classes:[], homework:[]})
    const [loaded, setLoaded] = useState(false)
    useEffect((e)=>{
        if(!loaded){
            checkAuth().then((e)=>{
                const p = e.email.split("@")[0].toUpperCase()
            searchStudent( p, "id").then((e)=>{
                console.log(e)
                getStudentsBatch(e.batchId).then((k)=>{
                    setStudentData(e)
                    setBatchData(k)
                    setLoaded(true)
                    console.log(k)
                })
            })
                
            })
        }
    })

    const handlePayment = () => {

    }
    const currentTime = () =>{ 
        const now = new Date();

// Extract the time
const hours = now.getHours();
const minutes = now.getMinutes();
const seconds = now.getSeconds();

// Format the time as HH:MM:SS
const currentTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
return currentTime
    }
   
    




  const generatePDF = (payment) => {

    const doc = new jsPDF();

    // Invoice Header
    doc.setFont("helvetica", "bold");
    doc.setFontSize(22);
    doc.setTextColor(40, 55, 71);
    doc.text("Invoice", 15, 20);
    doc.setFontSize(12);
    doc.text(`Batch: ${batchData.name || "N/A"}`, 15, 28);

    // Student & Company Data (Two-Column Layout)
    const startY = 40;
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");

    // Left: Student Data
    doc.text("Student Information", 15, startY);
    doc.text(`Name: ${studentData.firstName}`, 15, startY + 6);
    doc.text(`Student ID: ${studentData.studentID}`, 15, startY + 12);
    doc.text(`Phone: ${studentData.phone}`, 15, startY + 18);
    doc.text(`Email: ${studentData.email}`, 15, startY + 24);
    doc.text(`Address: ${studentData.presentAddress}`, 15, startY + 30);

    // Right: Company Data
    const companyStartX = 120;
    doc.text("Company Information", companyStartX, startY);
    doc.text("Aim Genius", companyStartX, startY + 6);
    doc.text("Phone: +880 1752-047247", companyStartX, startY + 12);
    doc.text("Email: support@myaimgenius.com", companyStartX, startY + 18);
    doc.text("Website: www.myaimgenius.com", companyStartX, startY + 24);

    // Payment Table
    const isPaid = payment.amount === payment.expectedAmount;
    const tableTitle = isPaid ? "Paid Receipt" : "Due Payment Notice";
    doc.setFont("helvetica", "bold");
    doc.setFontSize(14);
    doc.text(tableTitle, 15, 90);

    doc.autoTable({
      startY: 95,
      head: [["Installment", "Amount Paid", "Expected Amount", "Status", "Date"]],
      body: [
        [
          payment.installmentNumber,
          `${payment.amount} USD`,
          `${payment.expectedAmount} USD`,
          isPaid ? "Fully Paid" : "Payment Due",
          new Date(payment.paymentDate).toLocaleDateString(),
        ],
      ],
      styles: { fontSize: 10, cellPadding: 5, textColor: [0, 0, 0] },
      headStyles: { fillColor: [40, 55, 71], textColor: [255, 255, 255] },
      alternateRowStyles: { fillColor: [230, 230, 230] },
    });

    // Footer
    const printingDate = new Date().toLocaleDateString();
    doc.setFontSize(10);
    doc.setFont("helvetica", "italic");
    doc.setTextColor(100);
    doc.text(`Printed on: ${printingDate}`, 15, 280);
    doc.setFontSize(12);
    doc.setTextColor(40, 55, 71);
    doc.text(
      "Viel Glück für Ihre Karriere und Ihr Lernen",
      105,
      290,
      { align: "center" }
    );

    // Save PDF
    const fileName = `${tableTitle}-${payment.installmentNumber}.pdf`;
    doc.save(fileName);
  };

  
  
    return(<>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"
          rel="stylesheet"
        />
        <link
          href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
          rel="stylesheet"
        />
        <div className="min-h-screen  bg-gradient-to-r from-pink-300 to-indigo-300">
          <div className="grid grid-cols-12 gap-0">
            <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 xxl:col-span-8 px-6 py-6">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xxl:col-span-6">
                  {/* Start Nav Bar */}
                  <nav
                    x-data="{ active: 1 }"
                    className="bg-white p-4 rounded-xl shadow-xl flex items-center"
                  >
                    <a
                      href="#"
                      className="flex space-x-2 items-center mr-4 text-gray-400"
                    >
                      <i className="fas fa-columns fa-lg" />
                      <p className="font-semibold text-sm">Dashboard</p>
                    </a>
                    {/* <a
                      href="#"
                      className="flex space-x-2 items-center mr-4 text-gray-400"
                    >
                      <i className="fas fa-clipboard-list fa-lg" />
                      <p className="font-semibold text-sm">Payments</p>
                    </a> */}
                    <a
                      href="#"
                      className="flex space-x-2 items-center mr-4 text-gray-400"
                    >
                      <i className="fas fa-file-medical-alt fa-lg" />
                      <p className="font-semibold text-sm">Certificates</p>
                    </a>
                  </nav>
                  {/* End Nav Bar */}
                </div>
                <div className="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xxl:col-span-6">
                  {/* Start Card List */}
                  <div className="bg-white p-3 rounded-xl shadow-xl flex items-center justify-between">
                    <div className="flex space-x-6 items-center">
                      <div className="relative inline-flex">
                        <i className="fa-lg fas fa-sort-down w-2 h-2 absolute top-0 right-0 mx-5 my-2 pointer-events-none text-white" />
                        <select className="border-white rounded-xl text-white py-1  pl-5 pr-10 bg-pink-600 hover:bg-pink-700 focus:outline-none appearance-none">
                          <option>Level {batchData.level}</option>
                        </select>
                      </div>
                      <div className="flex space-x-2 items-center">
                        <p className="font-semibold">On-Going</p>
                        
                      </div>
                    </div>
                    <p className="font-semibold text-gray-400">{currentTime()}</p>
                  </div>
                  {/* End Card List */}
                </div>
                <div className="col-span-12 sm:col-span-12 md:col-span-5 lg:col-span-5 xxl:col-span-5">
        <p className="mb-2 font-bold text-lg">Installments</p>
        {studentData.payments.map((payment) => (
          <div
            key={payment._id}
            className="bg-white p-3 rounded-xl shadow-xl flex items-center justify-between mt-4"
          >
            <div className="flex space-x-6 items-center">
              <img
                src={
                  payment.amount === payment.expectedAmount
                    ? paidIcon
                    : unpaidIcon
                }
                className="w-auto h-12"
                alt={
                  payment.amount === payment.expectedAmount
                    ? "Paid Icon"
                    : "Unpaid Icon"
                }
              />
              <div>
                <p className="font-semibold text-base">
                  Installment {payment.installmentNumber}
                </p>
                <p className="font-semibold text-xs text-gray-400">
                  {payment.amount === payment.expectedAmount
                    ? "Payment Collected"
                    : "Partial/No Payment"}
                </p>
                <p className="text-sm text-gray-500">
                  Payment Date:{" "}
                  {new Date(payment.paymentDate).toLocaleDateString()}
                </p>
              </div>
            </div>
            <div className="flex space-x-2 items-center">
              <div
                className={`${
                  payment.amount === payment.expectedAmount
                    ? "bg-green-200"
                    : "bg-yellow-200"
                } rounded-md p-2 flex items-center`}
              >
                <p
                  className={`${
                    payment.amount === payment.expectedAmount
                      ? "text-green-600"
                      : "text-yellow-600"
                  } font-semibold text-xs`}
                >
                  {payment.amount === payment.expectedAmount
                    ? `+${payment.amount}`
                    : `Partial: +${payment.amount}`}
                </p>
              </div>
       
              {/* Add PDF Download Button */}
              <button
                onClick={() => generatePDF(payment)}
                className="bg-indigo-600 hover:bg-indigo-700 text-white px-2 py-1 rounded text-xs"
              >
                Download PDF
              </button>
            </div>
          </div>
        ))}
      </div>

                <div className="col-span-12 sm:col-span-12 md:col-span-7 lg:col-span-7 xxl:col-span-7">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="col-span-12 sm:col-span-12 md:col-span-7 lg:col-span-7 xxl:col-span-7">
                  <p className="mb-2 font-bold text-lg"> Classes</p>

                      {/* Start Card List */}
                      {batchData.classes.map((classItem) => (
  <div
    key={classItem._id}
    className="bg-white p-4 rounded-xl shadow-md flex flex-col space-y-4 mt-4"
  >
    <div className="flex justify-between items-center">
      <p className="font-semibold text-lg">{classItem.subject}</p>
      <p className="text-sm text-gray-500">
        {classItem.schedule?.day || "No Schedule Provided"}
      </p>
    </div>
    <div className="flex justify-between">
      <p className="text-sm text-gray-400">
        Teacher ID: {classItem.teacherId || "N/A"}
      </p>
      <p className="text-sm text-gray-400">
        Batch ID: {classItem.batchId}
      </p>
    </div>
    <div>
     
    </div>
  </div>
))}

                      {/* End Card List */}
                    </div>
                    <div className="col-span-12 sm:col-span-12 md:col-span-5 lg:col-span-5 xxl:col-span-5">
                      {/* Start Card List */}
                  <p className="mb-2 font-bold text-lg"> Homeworkds</p>

                      {batchData.homework.map((homework) => (
  <div
    key={homework._id}
    className="bg-white p-4 rounded-xl shadow-md flex flex-col space-y-4 mt-4"
  >
    <div className="flex justify-between items-center">
      <p className="font-semibold text-lg">Homework</p>
      <p className="text-sm text-gray-500">{homework.date}</p>
    </div>
    <div>
      <p className="text-sm text-gray-400">Batch ID: {homework.batchId}</p>
      <p className="text-sm text-gray-700 mt-2">
        {homework.description || "No Description Provided"}
      </p>
    </div>
    {homework.googleClassroomLink && (
      <a
        href={homework.googleClassroomLink}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-500 text-sm"
      >
        View on Google Classroom
      </a>
    )}
  </div>
))}

                      {/* End Card List */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-4 xxl:col-span-4 px-6 py-6">
              {/* Start profile Card */}
              <div className="bg-white rounded-xl p-4 shadow-xl">
                <div className="flex flex-col justify-center items-center">
                  <div className="w-32 h-32 rounded-full bg-gray-300 border-2 border-white mt-2">
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVxhAxJ4D7MOeTTj6kR9PBeZonW5HM7giKjTbEmR-HMBwf3G1VqGnlwpO1kWrdyIZu8_U&usqp=CAU"
                      className="rounded-full w-auto"
                    />
                  </div>
                  <p className="font-semibold text-xl mt-1">{studentData.firstName}</p>
                  <p className="font-semibold text-base text-gray-400">{studentData.studentID}</p>
                  <div
                    className="relative  p-4 rounded-lg shadow-xl w-full bg-cover bg-center h-32 mt-4"
                    style={{
                      backgroundImage:
                        'url("https://images.pexels.com/photos/1072179/pexels-photo-1072179.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500")'
                    }}
                  >
                    <div className="absolute inset-0 bg-gray-800 bg-opacity-50" />
                    <div className="relative w-full h-full px-4 sm:px-6 lg:px-4 flex items-center justify-center">
                      <div>
                        <h3 className="text-center text-white text-lg">
                          Batch name
                        </h3>
                        <h3 className="text-center text-white text-3xl mt-2 font-bold">
                          {batchData.name}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End profile Card */}
              {/* Start Card List */}

              <PaymentCard paymentIcon={paymentIcon} handlePayment={(installmentNumber, amountPaid) => {
    console.log('Installment:', installmentNumber);
    console.log('Amount Paid:', amountPaid);
    // Handle payment logic here
  }} studentData={studentData} />
             
              {/* End Card List */}
              {/* Start Card List */}
              <div className="bg-white rounded-xl p-4 shadow-xl mt-4">
                <div className="flex flex-col justify-center items-center">
                  <img
                    src="https://cdn3d.iconscout.com/3d/premium/thumb/upload-social-media-post-4291893-3569926.png"
                    className="w-auto h-40 rounded-lg"
                  />
                  <p className="font-semibold text-xl mt-1">Upload Data</p>
                  <p className="font-semibold text-sm text-gray-400 text-center">
                    Make your data as zip file <br /> and drag here
                  </p>
                  <button className=" mt-4 bg-indigo-600 hover:bg-indigo-700 shadow-xl text-white font-bold py-2 px-4 rounded">
                    Upload Now
                  </button>
                </div>
              </div>
              {/* End Card List */}
            </div>
          </div>
        </div>
      </>
      )
}

export default Dashboard
import { message } from "antd"
import bg from './bg.png'
import { useState } from "react"
import { auth } from "../../helpers/firebase"
import { signInWithEmailAndPassword } from "firebase/auth"
const LoginPage = () => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    return(
        <>
   <div style={{backgroundImage:`url(${bg})`}} className="h-screen flex justify-center items-center">
   
   
   <div className="container px-6 mx-auto">
  <div className="flex flex-col text-center md:text-left md:flex-row h-screen justify-evenly md:items-center">
    <div className="flex flex-col w-full">
      <div>
        <svg
          className="w-20 h-20 mx-auto md:float-left fill-stroke text-gray-800"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
          />
        </svg>
      </div>
      <h1 className="text-5xl text-gray-800 font-bold">Student Area</h1>
      <p className="w-5/12 mx-auto md:mx-0 text-gray-500">
        Check and monitorize your Learning and Performance from your personalized dashboard.
      </p>
    </div>
    <div className="w-full md:w-full lg:w-9/12 mx-auto md:mx-0">
      <div className="bg-white p-10 flex flex-col w-full shadow-xl rounded-xl">
        <h2 className="text-2xl font-bold text-gray-800 text-left mb-5">
          Sigin
        </h2>
        <form 
         onSubmit={((e)=>{
          e.preventDefault()
          signInWithEmailAndPassword(auth, `${email}@student.myaimgenius.com`, password ).then((e)=>{
              window.location = './dashboard'
          }).catch((e)=>{
            message.error("Incorrect ID / Password")
          })
        })} className="w-full">
          <div id="input" className="flex flex-col w-full my-5">
            <label htmlFor="username" className="text-gray-500 mb-2">
              Username
            </label>
            <input
              type="text"
            required

              id="username"
              onChange={(e)=>(setEmail(e.target.value))}
              placeholder="Student ID"
               className="appearance-none border-2 border-gray-100 rounded-lg px-4 py-3 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg"
            />
          </div>
          <div id="input" className="flex flex-col w-full my-5">
            <label htmlFor="password" className="text-gray-500 mb-2">
              Password
            </label>
            <input
            required
              type="password"
              id="password"
              onChange={(e)=>(setPassword(e.target.value))}
                placeholder="Password"
                className="appearance-none border-2 border-gray-100 rounded-lg px-4 py-3 placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:shadow-lg"
            />
          </div>
          <div id="button" className="flex flex-col w-full my-5">
            <button
              type="submit"
              
              className="w-full py-4 bg-green-600 rounded-lg text-green-100"
            >
              <div className="flex flex-row items-center justify-center">
                <div className="mr-2">
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                    />
                  </svg>
                </div>
                <div className="font-bold">Sigin</div>
              </div>
            </button>
          
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

   
   
   

        </div>
        
      </>
      
      )
}

export default LoginPage
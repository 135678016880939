import { message } from "antd"
import AdmissionPage from "../pages/Admission"
import Homepage from "../pages/Homepage"
import Leaderboard from "../pages/Leaderboard"
import LoginPage from "../pages/Login"
import NotFound from "../pages/Not Found"
import Dashboard from "../pages/Dashboard"

const pages = (slug, query) => {
  
    const pageList = {
        "leaderboard":  <Leaderboard/>,
        "dashboard":  <Dashboard/>,
        "admission": <AdmissionPage course={query}/>,
        "login": <LoginPage/>,
        "": <Homepage/>,
    }   



    if (pageList[slug] !== undefined) {
        window.history.pushState(null, null, `./${slug}`);
        return pageList[slug];
      } else {
        window.history.pushState(null, null, `./Not-Found`);
        return <NotFound/>;
      }    
}

export default pages
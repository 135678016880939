import './App.css';
import Header from './components/header';
import { useEffect, useState } from 'react';
import Loading from './components/loading';
import { checkAuth } from './helpers/firebase';
import { message } from 'antd';
import pages from './helpers/pages';
import Footer from './components/footer';

function App() {
  const [currentPage, setCurrentPage] = useState(<Loading/>)
  const [loaded, setLoaded] = useState(false)
  const [authState, setAuthState] = useState(false)
  const [queryParams, setQueryParams] = useState({})


  const emailRegex = /^[a-zA-Z0-9._%+-]+@student\.myaimgenius\.com$/;


  useEffect((e)=>{
    checkAuth().then((e)=>{
      if (emailRegex.test(e.email)) {
          setAuthState(true)
    } else {
      setAuthState(false)
    }    })
    const url = window.location.pathname.split("/")
   url.splice(0,1)
   if(loaded === false){

setQueryParams(window.location.href)
message.info(queryParams)
    setCurrentPage(pages([url[0]]))
    setLoaded(true)
   }
  })

  return (
    <div className="App">
      <Header loggedIn={authState} changePage={setCurrentPage} query={queryParams}/>
        {currentPage}

      <Footer changePage={setCurrentPage} query={queryParams}/>

      </div>
  );
}

export default App;

import { Button, Input, message, Result, Select } from "antd"
import bg from './bg.png'
import { useEffect, useState } from "react"
import { selfStudentRegister } from "../../helpers/firebase"
const AdmissionPage = ({course}) => {
    const [fullName, setFullName] = useState("")
    const [gender, setGender] = useState("male")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("88")
    const [presentAddress, setPresentAddress] = useState("")
    const [guardianPhone, setGuardianPhone] = useState("")
    const [relationWithGuardian, setRelationWithGuardian] = useState("")
    const [paymentDetails, setPaymentDetails] = useState("")
    const [resultData, setResultData] = useState(0)
    const [result, setResult] = useState(0)
    
  
function getQueryParam(param, url = window.location.href) {
  try {
      const urlObj = new URL(url);
      return urlObj.searchParams.get(param);
  } catch (error) {
      console.error('Invalid URL:', error);
      return null;
  }
}
useEffect((e)=>{
  getQueryParam()
})

    return(
        <>
        {result === 0 ? <div style={{backgroundImage:`url(${bg})`}} className="h-screen flex justify-center items-center">
          <div className="lg:w-2/5 md:w-1/2 w-2/3">
          {course}
            <form
                onSubmit={(e)=>{
                    e.preventDefault()
                    selfStudentRegister({
                        firstName: fullName,
                        lastName: "",
                        gender: gender,
                        phone: phone,
                        email: email,
                        id: "",
                        batch: "",
                        guardianPhone: guardianPhone,
                        preAdd: presentAddress,
                        perAdd: "",
                        notes: "",
                        duscount: "",
                        course: "",
                        relationWithGuardian: relationWithGuardian,
                        paymentDetails: paymentDetails
                    }).then((e)=>{
                        setResultData(e)
                        setResult(1)
                    }).catch((e)=>{
                        setResultData(e)
                        setResult(2)
                    })
                }}
            className="bg-white p-10 rounded-lg shadow-lg min-w-full">
              <h1 className="text-center text-2xl mb-6 text-gray-600 font-bold font-sans">
                Aim Genius Registration Form
              </h1>
              <div>
                <label
                  className="text-gray-800 font-semibold block my-3 text-md"
                  htmlFor="fullname"
                >
                  Full Name
                </label>
                <Input
                required
                onChange={(e)=>{setFullName(e.target.value)}}
                  className="w-full bg-gray-100 px-4 py-2 rounded-lg focus:outline-none"
                  type="text"
                  name="fullname"
                  id="fullname"
                  placeholder="Enter Your Full Name"
                />
              </div>
              <div>
                <label
                  className="text-gray-800 font-semibold block my-3 text-md"
                  htmlFor="gender"
                >
                  Gender
                </label>
                <Select 
                onSelect={(e)=>{setGender(e)}}
                size="large" defaultValue={"male"} className="w-full ">
                    <option value={"male"}>Male</option>
                    <option value={"female"}>Female</option>
                </Select>
              </div>
              <div>
                <label
                  className="text-gray-800 font-semibold block my-3 text-md"
                  htmlFor="email"
                >
                  Email
                </label>
                <Input
                required
                onChange={(e)=>{setEmail(e.target.value)}}
                  className="w-full bg-gray-100 px-4 py-2 rounded-lg focus:outline-none"
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter Your Email"
                />
              </div>
              <div>
                <label
                  className="text-gray-800 font-semibold block my-3 text-md"
                  htmlFor="phone"
                >
                  Phone
                </label>
                <Input
                required
                onChange={(e)=>{setPhone(e.target.value)}}
                  className="w-full bg-gray-100 px-4 py-2 rounded-lg focus:outline-none"
                  type="text"
                  name="phone"
                  id="phone"
                  defaultValue={"88"}
                  placeholder="Enter Your Phone Number"
                />
              </div>
              <div>
                <label
                  className="text-gray-800 font-semibold block my-3 text-md"
                  htmlFor="address"
                >
                  Present Address
                </label>
                <Input
                required
                onChange={(e)=>{setPresentAddress(e.target.value)}}
                  className="w-full bg-gray-100 px-4 py-2 rounded-lg focus:outline-none"
                  type="text"
                  name="address"
                  id="address"
                  placeholder="Enter Your Present Address"
                />
              </div>
              <div>
                <label
                  className="text-gray-800 font-semibold block my-3 text-md"
                  htmlFor="guardianPhone"
                >
                  Guardian Phone Number
                </label>
                <Input
                required
                onChange={(e)=>{setGuardianPhone(e.target.value)}}
                  className="w-full bg-gray-100 px-4 py-2 rounded-lg focus:outline-none"
                  type="text"
                  name="guardianPhone"
                  id="guardianPhone"
                  placeholder="Enter Your Guardian Phone Number"
                />
              </div>
              <div>
                <label
                  className="text-gray-800 font-semibold block my-3 text-md"
                  htmlFor="relationWithGuardian"
                >
                  Relation with Guardian
                </label>
                <Input
                required
                onChange={(e)=>{setRelationWithGuardian(e.target.value)}}
                  className="w-full bg-gray-100 px-4 py-2 rounded-lg focus:outline-none"
                  type="text"
                  name="relationWithGuardian"
                  id="relationWithGuardian"
                  placeholder="Enter Your Relation With The Guardian Mentioned Above"
                />
              </div>
              <div>
                <label
                  className="text-gray-800 font-semibold block my-3 text-md"
                  htmlFor="paymentDetails"
                >
                  Payment Details
                </label>
                <Input
                required
                onChange={(e)=>{setPaymentDetails(e.target.value)}}
                  className="w-full bg-gray-100 px-4 py-2 rounded-lg focus:outline-none"
                  type="text"
                  name="paymentDetails"
                  id="paymentDetails"
                  placeholder="Enter Your Payment Details"
                />
              </div>
              <button
                type="submit"
                className="w-full mt-6 bg-indigo-600 rounded-lg px-4 py-2 text-lg text-white tracking-wide font-semibold font-sans"
              >
                Register
              </button>
            
            </form>
          </div>
        </div> : result === 1 ? <div  style={{backgroundImage:`url(${bg})`}}  className="h-screen">
        <div className="p-10 bg-white rounded-xl w-2/3 mx-auto shadow-2xl pt-[20vh]">
        <Result
icon={<></>}
    status="success"
    title={`Successfully Registered `}
    subTitle={`Your registration is successfully recieved with the ID: ${resultData}`}
    extra={[
      <a className="px-3 py-2 rounded-lg bordered mt-4" href="tel:+8801752047247" type="primary" key="console">
        Call us
      </a>,
      <a className="px-3 py-2 rounded-lg bordered mt-4" href="https://me.myaimgenius.com" key="buy">Go Home</a>,
    ]}
  />
        </div>
        </div > : <div  style={{backgroundImage:`url(${bg})`}}  className="h-screen"> 
        <div className="p-10 bg-white rounded-xl w-2/3 mx-auto shadow-2xl pt-[20vh]">
        <Result
        icon={<></>}
    status="failed"
    title="Failed to register "
    subTitle={`Your registration is failed. Show this to Aim Genius Dev Team: ${resultData}`}
    extra={[
      <a className="px-3 py-2 rounded-lg bordered mt-4" href="tel:+8801752047247" type="primary" key="console">
        Call us
      </a>,
      <a className="px-3 py-2 rounded-lg bordered mt-4" href="mailto:support@myaimgenius.com">Email Support Team</a>,
    ]}
  /> 
  </div>
  </div>
  }
        
      </>
      
      )
}

export default AdmissionPage
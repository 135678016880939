import './style.css';
import bg from './bg01.png';
import { useEffect, useState } from 'react';
import { getLeaderboard } from '../../helpers/firebase';
import { Divider } from 'antd';

const Leaderboard = () => {
  const [students, setStudents] = useState({ 0: [], 1: [], 2: [], 3: [] });
  const categories = [0, 1, 2, 3];

  useEffect(() => {
    const fetchLeaderboard = async () => {
      const data = await getLeaderboard();
      setStudents(data.newOrder);
    };

    fetchLeaderboard();
  }, []);

  return (
    <section
      className="bg-cover bg-no-repeat bg-center min-h-screen py-12"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="container mx-auto px-4">
        {/* Header */}
        <div className="text-center bg-gradient-to-r from-gray-800 to-gray-900 py-6 rounded-xl mb-8 shadow-lg">
          <h1 className="text-3xl md:text-4xl font-bold bg-gradient-to-r from-blue-400 to-teal-300 text-transparent bg-clip-text">
            Leaderboard
          </h1>
          <p className="text-gray-300 mt-2">
            Welcome to Aim Genius Leaderboard! <br />
            See how our students are performing and progressing on their journey to mastering the German Language and achieving their dreams in Germany.
          </p>
          <Divider>
            <h1 className="text-xl font-bold text-gray-200">How it Works?</h1>
          </Divider>
          <p className="text-gray-400">
            This leaderboard ranks students based on their performance in:
            <ul className="list-disc list-inside mt-2">
              <li>
                <b className="text-gray-200">Exams:</b> Regular test scores contribute to the ranking.
              </li>
              <li>
                <b className="text-gray-200">Assignments:</b> Timely and accurate completion of assignments.
              </li>
              <li>
                <b className="text-gray-200">Participation:</b> Engagement in class activities, quizzes, and challenges.
              </li>
            </ul>
            <Divider></Divider>
            <span className='text-white'>Note: The leaderboard displays the Top 10 Students from each batch. Keep working hard to see your name among the best in your group!</span>
          </p>
        </div>

        {/* Leaderboard */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 ">
          {categories.map((category, index) => (
            <div
              key={index}
              className="rounded-lg shadow-md bg-gradient-to-b from-gray-800 to-gray-900 p-4 border border-gray-700"
            >
              {/* Batch Details */}
              <h2 className="text-lg font-semibold text-gray-100 mb-2">
                Batch: {category === 0 ? 'A1' : category === 1 ? 'A1 - X' : category === 2 ? 'A2' : 'B1'}
              </h2>
              <p className="text-sm text-gray-400 mb-4">
                {`${
                  category === 0
                    ? 'Beginner Batch'
                    : category === 1
                    ? 'Advanced A1 Batch'
                    : category === 2
                    ? 'Intermediate Batch'
                    : 'Advanced B1 Batch'
                } | Focused on Excellence`}
              </p>
<br></br>
              {/* Students */}
              <div className="space-y-4 ">
                {students[category].map((student, rank) => (
                  <div
                    key={rank}
                    className={`flex items-center gap-3 p-3 rounded-md shadow-md transition-all ${
                      rank === 0
                        ? 'bg-gradient-to-r from-yellow-400 to-yellow-600 animate-bounce'
                        : rank === 1
                        ? 'bg-gradient-to-r from-gray-500 to-gray-600 animate-pulse'
                        : rank === 2
                        ? 'bg-gradient-to-r from-orange-400 to-orange-600 animate-pulse'
                        : 'bg-gray-700'
                    }`}
                  >
                    {/* Avatar */}
                    <div
                      className={`w-12 h-12 rounded-full bg-cover bg-center border ${
                        rank === 0
                          ? 'border-yellow-500'
                          : rank === 1
                          ? 'border-gray-400'
                          : rank === 2
                          ? 'border-orange-500'
                          : 'border-gray-500'
                      }`}
                      style={{
                        backgroundImage: `url('data:image/svg+xml;utf8,${encodeURIComponent(
                          `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="64" height="64" fill="none">
                          <circle cx="32" cy="32" r="30" fill="${
                            rank === 0
                              ? '#FFD700'
                              : rank === 1
                              ? '#C0C0C0'
                              : rank === 2
                              ? '#CD7F32'
                              : '#555555'
                          }"/>
                        </svg>`
                        )}')`,
                      }}
                    ></div>

                    {/* Student Details */}
                    <div className="flex-1">
                      <p className="text-base font-medium text-gray-100">{student.name}</p>
                      <p className="text-sm text-gray-400">{student.points} points</p>
                    </div>
                    <p className="text-lg font-bold text-gray-200">#{rank + 1}</p>
                  </div>
                ))}

                {students[category].length === 0 && (
                  <p className="text-center text-gray-400 text-sm">No data available</p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Leaderboard;

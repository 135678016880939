import { Divider } from "antd"
import bg from './bg.png'
const Homepage = () => {

    return(
    
    <div className="overflow-x-hidden bg-gray-100">
    <>
  <link
    href="https://fonts.googleapis.com/css?family=Mitr|Roboto+Slab|Source+Sans+Pro&display=swap"
    rel="stylesheet"
  />
  <div className="bg-indigo-900 relative overflow-hidden">
    <div className="inset-0 bg-black opacity-25 absolute" />
   
    <div className="container mx-auto px-6 md:px-12 relative z-10 flex items-center py-24 xl:py-40">
      <div className="lg:w-3/5 xl:w-2/5 flex flex-col items-start relative z-10">
        <span className="font-mitr uppercase text-indigo-500"></span>
        <h1 className="font-roboto-slab text-4xl sm:text-6xl text-red-400 leading-tight mt-4">
          Learn German <br />   Build your Career
        </h1>
        <div className="max-w-md">
          <p className="font-source-sans-pro text-indigo-500 mt-6 text-lg">
            With Aim Genius
          </p>
        </div>
        <a
          href="#"
          className="block bg-indigo-500 hover:bg-indigo-400 py-2 px-4 rounded-full text-sm font-mitr text-white uppercase mt-10"
        >
          Get started
        </a>
      </div>
      <svg
        className="hidden sm:block absolute bottom-0 right-0 -mr-40 lg:mr-0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 645.06 372.73"
      >
        <defs />
        <defs>
          <radialGradient
            id="a"
            cx="416.96"
            cy="273.37"
            r="226.21"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#6e3f63" />
            <stop offset=".15" stopColor="#633d62" />
            <stop offset=".41" stopColor="#473660" />
            <stop offset=".5" stopColor="#3b335f" />
            <stop offset=".6" stopColor="#38315c" stopOpacity=".88" />
            <stop offset=".76" stopColor="#302b55" stopOpacity=".57" />
            <stop offset=".97" stopColor="#242249" stopOpacity=".07" />
            <stop offset={1} stopColor="#222147" stopOpacity={0} />
          </radialGradient>
        </defs>
        <g data-name="Capa 1">
          <path fill="url(#a)" d="M645.06 0v372.73H4.2L0 0h645.06z" />
          <path
            fill="#d29b72"
            d="M487.31 222.05a2.41 2.41 0 014.81 0c0 1.33-1.08 49.27-2.41 49.27s-2.4-47.94-2.4-49.27z"
          />
          <circle cx="489.71" cy="222.05" r="1.69" fill="#e8c275" />
          <path
            fill="#f3eece"
            d="M488.63 222.05a1.08 1.08 0 012.16 0 1.08 1.08 0 11-2.16 0z"
          />
          <path
            fill="#d29b72"
            d="M338.77 252.5a1.39 1.39 0 012.77 0c0 .76-.62 28.28-1.38 28.28s-1.39-27.52-1.39-28.28z"
          />
          <path fill="#e8c275" d="M339.19 252.5a1 1 0 111 1 1 1 0 01-1-1z" />
          <circle cx="340.16" cy="252.5" r=".62" fill="#f3eece" />
          <g opacity=".5">
            <path
              fill="#d29b72"
              d="M370.16 172.82a1.8 1.8 0 013.6 0c0 1-.8 36.87-1.8 36.87s-1.8-35.88-1.8-36.87z"
            />
            <path
              fill="#e8c275"
              d="M370.7 172.82a1.26 1.26 0 111.26 1.26 1.26 1.26 0 01-1.26-1.26z"
            />
            <circle cx="371.96" cy="172.82" r=".81" fill="#f3eece" />
          </g>
          <path
            fill="#f3eece"
            d="M466.22 225.08a1.29 1.29 0 11-1.28-1.28 1.28 1.28 0 011.28 1.28zM350.22 186a1.29 1.29 0 01-2.57 0 1.29 1.29 0 112.57 0z"
          />
          <circle cx="497.5" cy="176.37" r="1.29" fill="#f3eece" />
          <path
            fill="#f3eece"
            d="M370.17 248.47a1.29 1.29 0 11-1.29-1.29 1.28 1.28 0 011.29 1.29z"
          />
          <circle cx="277.03" cy="215.66" r="1.29" fill="#f3eece" />
          <path
            fill="#fff"
            d="M330.41 224.8a.65.65 0 11-.64-.64.65.65 0 01.64.64zM469.82 227.34a.65.65 0 11-.65-.64.65.65 0 01.65.64z"
          />
          <circle cx="260.82" cy="273.25" r="1.29" fill="#f3eece" />
          <path
            fill="#fff"
            d="M265.7 275.52a.65.65 0 11-.64-.65.64.64 0 01.64.65zM532.33 266.64a.65.65 0 11-.65-.65.65.65 0 01.65.65z"
          />
          <circle cx="528.92" cy="271.89" r="1.29" fill="#f3eece" />
          <path
            fill="#fff"
            d="M557.69 179.87a.64.64 0 11-.47 1.2.64.64 0 01.47-1.2z"
          />
          <path
            fill="#f3eece"
            d="M561.81 183.76a1.29 1.29 0 11-1.67.73 1.29 1.29 0 011.67-.73zM540 143.22a1.29 1.29 0 11-1.67.73 1.29 1.29 0 011.67-.73z"
          />
          <path
            fill="#fff"
            d="M281.65 146.15a.64.64 0 01-.47 1.2.64.64 0 11.47-1.2z"
          />
          <path
            fill="#f3eece"
            d="M285.76 150a1.29 1.29 0 11-1.66.73 1.29 1.29 0 011.66-.73z"
          />
          <circle cx="333.34" cy="222.07" r="1.29" fill="#f3eece" />
          <circle
            cx="338.06"
            cy="135.84"
            r="13.93"
            fill="none"
            stroke="#5c4f84"
            strokeMiterlimit={10}
            strokeWidth=".735"
            transform="rotate(-22.72 338.064 135.833)"
          />
          <circle
            cx="304.19"
            cy="187.52"
            r="10.74"
            fill="none"
            stroke="#5c4f84"
            strokeMiterlimit={10}
            strokeWidth=".735"
            transform="rotate(-80.82 304.168 187.524)"
          />
          <circle
            cx="509.71"
            cy="183.41"
            r="3.98"
            fill="#fff"
            fillOpacity=".2"
          />
          <path
            fill="#fff"
            fillOpacity=".3"
            d="M509.71 188.07a.68.68 0 01-.68-.67v-8a.68.68 0 01.68-.67.67.67 0 01.67.67v8a.67.67 0 01-.67.67z"
          />
          <path
            fill="#fff"
            fillOpacity=".3"
            d="M514.37 183.41a.67.67 0 01-.67.67h-8a.67.67 0 01-.67-.67.68.68 0 01.67-.68h8a.68.68 0 01.67.68z"
          />
          <path
            fill="#fff"
            d="M512.53 183.41a.67.67 0 01-.67.67h-4.3a.67.67 0 01-.67-.67.68.68 0 01.67-.68h4.3a.68.68 0 01.67.68z"
          />
          <path
            fill="#fff"
            d="M509.71 186.23a.68.68 0 01-.68-.67v-4.3a.68.68 0 01.68-.67.67.67 0 01.67.67v4.3a.67.67 0 01-.67.67z"
          />
          <path
            fill="#fff"
            fillOpacity=".2"
            d="M505.06 114.25a4 4 0 114 4 4 4 0 01-4-4z"
          />
          <path
            fill="#fff"
            fillOpacity=".3"
            d="M509 118.91a.67.67 0 01-.67-.67v-8a.68.68 0 01.67-.68.68.68 0 01.68.68v8a.67.67 0 01-.68.67z"
          />
          <path
            fill="#fff"
            fillOpacity=".3"
            d="M513.7 114.25a.67.67 0 01-.68.67h-8a.67.67 0 01-.68-.67.67.67 0 01.68-.68h8a.67.67 0 01.68.68z"
          />
          <path
            fill="#fff"
            d="M511.86 114.25a.67.67 0 01-.68.67h-4.29a.67.67 0 01-.68-.67.67.67 0 01.68-.68h4.29a.67.67 0 01.68.68z"
          />
          <path
            fill="#fff"
            d="M509 117.07a.67.67 0 01-.67-.67v-4.3a.68.68 0 01.67-.68.68.68 0 01.68.68v4.3a.67.67 0 01-.68.67z"
          />
          <path
            fill="#fff"
            fillOpacity=".2"
            d="M288 251.91a4 4 0 114 4 4 4 0 01-4-4z"
          />
          <path
            fill="#fff"
            fillOpacity=".3"
            d="M292 256.57a.67.67 0 01-.67-.67v-8a.68.68 0 01.67-.68.68.68 0 01.67.68v8a.67.67 0 01-.67.67z"
          />
          <path
            fill="#fff"
            fillOpacity=".3"
            d="M296.61 251.91a.67.67 0 01-.67.67h-8a.67.67 0 01-.67-.67.67.67 0 01.67-.68h8a.67.67 0 01.67.68z"
          />
          <path
            fill="#fff"
            d="M294.77 251.91a.67.67 0 01-.67.67h-4.3a.67.67 0 01-.67-.67.67.67 0 01.67-.68h4.3a.67.67 0 01.67.68z"
          />
          <path
            fill="#fff"
            d="M292 254.73a.67.67 0 01-.67-.67v-4.3a.68.68 0 01.67-.68.68.68 0 01.67.68v4.3a.67.67 0 01-.67.67z"
          />
          <path
            fill="#fff"
            fillOpacity=".2"
            d="M336.2 181.15a4 4 0 114 4 4 4 0 01-4-4z"
          />
          <path
            fill="#fff"
            fillOpacity=".3"
            d="M340.17 185.81a.67.67 0 01-.67-.67v-8a.67.67 0 01.67-.67.67.67 0 01.68.67v8a.67.67 0 01-.68.67z"
          />
          <path
            fill="#fff"
            fillOpacity=".3"
            d="M344.84 181.15a.68.68 0 01-.68.67h-8a.67.67 0 01-.67-.67.67.67 0 01.67-.67h8a.68.68 0 01.68.67z"
          />
          <path
            fill="#fff"
            d="M343 181.15a.68.68 0 01-.68.67H338a.67.67 0 01-.67-.67.67.67 0 01.67-.67h4.3a.68.68 0 01.7.67z"
          />
          <path
            fill="#fff"
            d="M340.17 184a.67.67 0 01-.67-.67V179a.67.67 0 01.67-.67.67.67 0 01.68.67v4.3a.67.67 0 01-.68.7z"
          />
          <circle
            cx="337.46"
            cy="135.87"
            r="9.46"
            fill="#b96a51"
            transform="rotate(-81.16 337.446 135.873)"
          />
          <path
            fill="#a65054"
            d="M342.44 127.83a9.41 9.41 0 00-6.51-1.28 10.56 10.56 0 014.92 8.15c.63 5.79-4.19 10.36-4.41 10.56a9.45 9.45 0 006-17.43z"
          />
          <path
            fill="#854354"
            d="M341.94 128.33a8.78 8.78 0 01-4.06 16.24 9.32 9.32 0 002-16.78 9.55 9.55 0 00-1.35-.69 8.9 8.9 0 013.41 1.23z"
          />
          <path
            fill="#dbb07b"
            d="M331.05 141a3 3 0 114.09-1 3 3 0 01-4.09 1z"
            opacity=".3"
          />
          <path
            fill="#dbb07b"
            d="M330.81 139.82a1.71 1.71 0 112.35-.55 1.7 1.7 0 01-2.35.55zM333.64 133.24a3 3 0 114.09-1 3 3 0 01-4.09 1z"
            opacity=".3"
          />
          <path
            fill="#dbb07b"
            d="M333.42 131.79a1.6 1.6 0 112.2-.52 1.6 1.6 0 01-2.2.52zM329.81 134.58a1.47 1.47 0 112-.47 1.46 1.46 0 01-2 .47z"
            opacity=".3"
          />
          <path
            fill="#dbb07b"
            d="M329.78 134.1a.79.79 0 01-.25-1.09.79.79 0 011.34.83.79.79 0 01-1.09.26z"
            opacity=".3"
          />
          <path
            fill="#cd936d"
            d="M334.25 128.23a8 8 0 00-4.79 7.45 8.75 8.75 0 006.2 8.19 10.83 10.83 0 01-4.3-7.36 11.49 11.49 0 012.89-8.28z"
            opacity=".5"
          />
          <path
            fill="#dbb07b"
            d="M334.25 128.23s-4.82 2.58-4.43 7.76a9.78 9.78 0 005.84 7.88s-6.06-1.56-6.45-7.84a7.91 7.91 0 015.04-7.8z"
          />
          <path
            fill="#d8e5f4"
            d="M355.59 130.92c.41 1.13-3.17 3.51-8.73 6-2.3 1-4.94 2.1-7.77 3.11s-5.74 1.92-8.24 2.57c-5.77 1.51-9.93 1.9-10.32.78s2.73-3.21 7.69-5.54c.07.32.15.63.25.94-1.9 1.06-3 1.94-2.81 2.42s1.71.49 4 0a63.87 63.87 0 008.91-2.56 66.75 66.75 0 008.28-3.52c2.24-1.18 3.54-2.18 3.35-2.71s-1.68-.49-4 0c-.12-.3-.25-.59-.4-.88 5.53-1.36 9.41-1.68 9.79-.61z"
          />
          <path
            fill="#d59145"
            d="M355.65 131.29c.41 1.13-3.17 3.51-8.73 6-2.3 1-4.93 2.1-7.77 3.11s-5.74 1.91-8.24 2.57c-5.77 1.5-9.92 1.9-10.32.78s2.76-3.22 7.71-5.55a7.45 7.45 0 00.29.92c-1.91 1-3 2-2.87 2.45s1.71.49 4 0a66.76 66.76 0 008.91-2.56 65.14 65.14 0 008.28-3.53c2.24-1.17 3.54-2.17 3.35-2.7s-1.62-.51-3.91-.07a7.46 7.46 0 00-.35-.89c5.49-1.31 9.27-1.61 9.65-.53z"
          />
          <path
            fill="#edcf8e"
            d="M355.59 130.92c.41 1.13-3.17 3.51-8.73 6-2.3 1-4.94 2.1-7.77 3.11s-5.74 1.92-8.24 2.57c-5.77 1.51-9.93 1.9-10.32.78s2.73-3.21 7.69-5.54c.07.32.15.63.25.94-1.9 1.06-3 1.94-2.81 2.42s1.71.49 4 0a63.87 63.87 0 008.91-2.56 66.75 66.75 0 008.28-3.52c2.24-1.18 3.54-2.18 3.35-2.71s-1.68-.49-4 0c-.12-.3-.25-.59-.4-.88 5.53-1.36 9.41-1.68 9.79-.61z"
          />
          <path
            fill="#f5e5c2"
            d="M352.11 130.58c1.34 0 3.21-.35 3.13.67s-5.24 4.49-17.24 8.88c0 0 11.56-5.11 14.09-6.88s2.5-2.35.02-2.67z"
          />
          <path
            fill="#f4e7b1"
            d="M324.83 139.85c-1.09.73-3.88 2.42-3.89 3.26s4.73.47 6.18.07c0 0-4.16.44-5.11-.31-.71-.57 2.82-3.02 2.82-3.02z"
          />
          <path
            fill="#ecd26a"
            d="M328.2 138.58a19.89 19.89 0 00-2.18 1.33c-1 .71-2.12 1.59-1.45 2.06s2.16.41 4.81-.07 6.76-1.63 6.76-1.63-5.8 1.92-8.31 2.36-4.45.29-4.7-.37c-.3-.82 2.42-2.78 5-3.92z"
          />
          <path
            fill="#7e9acc"
            d="M483.7 148.15a7.1 7.1 0 11-8.57-5.22 7.1 7.1 0 018.57 5.22z"
          />
          <g fill="#d3e8f7" opacity=".5">
            <path d="M475 145.87c-.5-.57-1.33-1.42-2-2.07a7.25 7.25 0 00-1.08.81c1.15 1 2.25 2 2.56 2.33s1.52-.04.52-1.07zM483 153.28c-1.45-.85-4.14-2.44-5.28-3.18-1.61-1-2.84-.78-3.32-1.19s-2.24-2.21-3.34-3.24a7.15 7.15 0 00-.44.68c.88 1 2.3 2.53 2.61 2.84.46.47.28 1.16-.27.67-.34-.31-1.82-1.46-2.88-2.29a7.42 7.42 0 00-.33 1.5c1.59 1.43 3.54 2.77 4.14 2.83s1.4-1.22 2.22-.77c.61.34 4.09 2.54 5.8 3.61a6.78 6.78 0 001.09-1.46zM478.48 156.72a7.2 7.2 0 002.4-1.09c-1.38-.75-3.57-1.91-4.2-2.12-.93-.33-.83 1.31-1.58 1.35s-2.88-1.47-5.2-3.43v.07a7.1 7.1 0 008.58 5.22zM478.09 142.85c.06.38.32.78 1 1.36 1.1.9 2 1.48 2.37 1.87s-.65.54-.12 1.18a14.76 14.76 0 002.49 1.79c0-.3-.09-.6-.16-.9a7.09 7.09 0 00-5.58-5.3zM478 146.94c-.46-.67.26-1.39-.28-2.17a23.91 23.91 0 00-1.74-2 6.67 6.67 0 00-1.64.4 23.8 23.8 0 012.12 2.59c.28.54-.58 1.42-.17 1.73s5.54 3.63 7.24 4.73a7.16 7.16 0 00.37-1.6c-1.51-.81-5.57-3.14-5.9-3.68z" />
          </g>
          <path
            fill="#5f71ae"
            d="M483.13 146.6a7.07 7.07 0 00-3.68-3.36 7.93 7.93 0 010 7.15c-1.85 4-6.73 5-6.95 5.06a7.1 7.1 0 0010.66-8.85z"
            opacity=".5"
          />
          <path
            fill="#5f71ae"
            d="M482.61 146.72a6.6 6.6 0 01-8.95 8.84 7 7 0 007.84-10 7.89 7.89 0 00-.6-1 6.61 6.61 0 011.71 2.16z"
          />
          <path
            fill="#d3e8f7"
            d="M477.71 143.65a6 6 0 00-6 2.91 6.59 6.59 0 00.79 7.68 8.12 8.12 0 01.11-6.4 8.71 8.71 0 015.1-4.19z"
            opacity=".7"
          />
          <path
            fill="#d3e8f7"
            d="M477.71 143.65s-4.1-.22-5.87 3.25a7.35 7.35 0 00.67 7.34s-3.28-3.37-1.08-7.55a6 6 0 016.28-3.04z"
          />
          <circle
            cx="304.62"
            cy="187.81"
            r="7.09"
            fill="#7e9acc"
            transform="rotate(-71.27 304.65 187.81)"
          />
          <g fill="#d3e8f7" opacity=".5">
            <path d="M302.86 183.85c-.51-.56-1.33-1.41-2-2.07a7.22 7.22 0 00-1.07.82c1.15 1.05 2.24 2 2.55 2.33s1.44-.04.52-1.08zM310.81 191.27c-1.44-.85-4.13-2.44-5.28-3.18-1.61-1-2.83-.78-3.32-1.19s-2.24-2.21-3.34-3.24a6 6 0 00-.43.68c.88 1 2.29 2.53 2.6 2.84.46.47.28 1.16-.27.67-.34-.31-1.81-1.46-2.87-2.29a6.27 6.27 0 00-.33 1.5c1.58 1.43 3.54 2.76 4.13 2.82s1.41-1.21 2.22-.76c.61.34 4.1 2.54 5.81 3.61a7 7 0 001.08-1.46zM306.3 194.7a7.07 7.07 0 002.4-1.08c-1.39-.75-3.58-1.91-4.2-2.13-.93-.32-.84 1.31-1.58 1.36s-2.89-1.47-5.21-3.43a.64.64 0 000 .07 7.09 7.09 0 008.59 5.21zM305.9 180.84c.06.38.33.78 1 1.35 1.1.91 2 1.49 2.37 1.88s-.65.54-.11 1.18a14.65 14.65 0 002.48 1.78 5.87 5.87 0 00-.16-.9 7.08 7.08 0 00-5.58-5.29zM305.76 184.93c-.46-.67.27-1.4-.27-2.17a25.6 25.6 0 00-1.74-2l-.81.15a7.46 7.46 0 00-.84.26 24.47 24.47 0 012.13 2.59c.28.54-.59 1.41-.17 1.73s5.54 3.63 7.23 4.73a6.75 6.75 0 00.38-1.61c-1.46-.81-5.53-3.14-5.91-3.68z" />
          </g>
          <path
            fill="#5f71ae"
            d="M310.94 184.59a7.07 7.07 0 00-3.67-3.37 8 8 0 010 7.15c-1.86 4-6.74 5-7 5.06a7.1 7.1 0 0010.66-8.84z"
            opacity=".5"
          />
          <path
            fill="#5f71ae"
            d="M310.42 184.71a6.6 6.6 0 01-8.94 8.84 7 7 0 007.83-10 6.78 6.78 0 00-.59-1 6.58 6.58 0 011.7 2.16z"
          />
          <path
            fill="#d3e8f7"
            d="M305.52 181.64a6 6 0 00-6 2.91 6.56 6.56 0 00.79 7.68 8.08 8.08 0 01.11-6.4 8.65 8.65 0 015.1-4.19z"
            opacity=".7"
          />
          <path
            fill="#d3e8f7"
            d="M305.52 181.64s-4.09-.23-5.86 3.25a7.33 7.33 0 00.67 7.34s-3.29-3.37-1.08-7.55a5.93 5.93 0 016.27-3.04z"
          />
          <circle
            cx="476.81"
            cy="149.82"
            r="9.43"
            fill="none"
            stroke="#5c4f84"
            strokeMiterlimit={10}
            strokeWidth=".798"
          />
          <circle
            cx="476.81"
            cy="149.82"
            r="14.36"
            fill="none"
            stroke="#514575"
            strokeDasharray="2.255 2.255 0 2.255 2.255 0"
            strokeLinecap="round"
            strokeMiterlimit={10}
            strokeWidth="1.102"
          />
          <circle
            cx="338.06"
            cy="135.84"
            r="21.74"
            fill="none"
            stroke="#514575"
            strokeDasharray="3.414 3.414 0 3.414 3.414 0"
            strokeLinecap="round"
            strokeMiterlimit={10}
            strokeWidth="1.102"
            transform="rotate(-31.72 338.036 135.845)"
          />
          <path
            fill="none"
            stroke="#5c5381"
            strokeMiterlimit={10}
            strokeWidth=".735"
            d="M534.73 238.5l6.29-3.83M520.2 227.9l14.53 10.6 6.18 7.56 7.03-1.19M527.99 207.14l11.3 13.18 1.73 14.35 5.86 3.5 1.06 6.7 16.49 5.09 15.32.32"
          />
          <path
            fill="#f3eece"
            d="M526.71 207.14a1.28 1.28 0 111.28 1.28 1.27 1.27 0 01-1.28-1.28z"
          />
          <circle cx="539.29" cy="220.32" r="1.41" fill="#f3eece" />
          <path
            fill="#f3eece"
            d="M519.34 227.9a.87.87 0 11.86.86.86.86 0 01-.86-.86zM533.59 238.5a1.14 1.14 0 111.14 1.14 1.14 1.14 0 01-1.14-1.14zM540.16 234.67a.86.86 0 11.86.86.85.85 0 01-.86-.86zM563.57 250a.86.86 0 11.86.86.86.86 0 01-.86-.86z"
          />
          <circle cx="546.88" cy="238.17" r=".65" fill="#f3eece" />
          <path
            fill="#f3eece"
            d="M540.26 246.06a.65.65 0 111.3 0 .65.65 0 11-1.3 0zM547 244.87a1 1 0 111 1 1 1 0 01-1-1z"
          />
          <circle cx="579.75" cy="250.28" r="1.08" fill="#f3eece" />
          <ellipse
            cx="418.58"
            cy="322.91"
            fill="#504382"
            rx="62.46"
            ry="25.6"
          />
          <path
            fill="#6a4f7c"
            d="M351.38 301.11a28.72 28.72 0 0157.42 0 7.22 7.22 0 010 .8 7.31 7.31 0 010 .81c-.32 4-3.12 7.87-8.4 10.93-11.21 6.47-29.41 6.47-40.64 0-5.26-3.06-8.06-7-8.38-10.93a7.31 7.31 0 010-.81 7.22 7.22 0 010-.8z"
          />
          <path
            fill="#855c75"
            d="M367.63 276.05a28.68 28.68 0 0141.17 25.06 7.22 7.22 0 010 .8 7.31 7.31 0 010 .81c-.32 4-3.12 7.87-8.4 10.93a38.26 38.26 0 01-14.4 4.49c7.45-1.28 12.27-9.6 10-18.79-2.69-10.66-13.46-26.06-28.37-23.3z"
          />
          <path
            fill="#5a477d"
            d="M351.38 302.72a7.31 7.31 0 010-.81 7.22 7.22 0 010-.8 28.54 28.54 0 019.75-20.75c-3.12 3.21-10.06 15.3-2.52 24.52 5 6.06 28.51 14.71 47.88 3.59a20.06 20.06 0 01-6.09 5.18c-11.21 6.47-29.41 6.47-40.64 0-5.26-3.06-8.06-6.96-8.38-10.93z"
          />
          <path
            fill="#a1727c"
            d="M380.38 279.65c-1.28 2.93 1.19 6.85 5.52 8.75s8.89 1.07 10.17-1.86-1.18-6.84-5.51-8.74-8.89-1.08-10.18 1.85z"
            opacity=".5"
            style={{ mixBlendMode: "lighten" }}
          />
          <path
            fill="#926279"
            d="M397 291.57c-.67 1.52.3 3.43 2.17 4.25s3.93.25 4.6-1.28-.3-3.42-2.17-4.25-3.91-.29-4.6 1.28z"
            opacity=".5"
            style={{ mixBlendMode: "lighten" }}
          />
          <path
            fill="#443b6c"
            d="M400.4 313.65c-11.21 6.47-29.41 6.47-40.64 0a20.06 20.06 0 01-6.07-5.17c.06.06 8.91 8.65 26.35 8.65s26.45-8.66 26.45-8.66a20.06 20.06 0 01-6.09 5.18z"
          />
          <path
            fill="#6a4f7c"
            d="M485.83 306.43a26 26 0 00-51.93 0v1.45c.29 3.59 2.83 7.12 7.6 9.88 10.13 5.86 26.59 5.86 36.75 0 4.75-2.76 7.28-6.29 7.58-9.88v-.72c0-.24.02-.48 0-.73z"
          />
          <path
            fill="#855c75"
            d="M471.13 283.76a25.94 25.94 0 00-37.23 22.67v1.45c.29 3.59 2.83 7.12 7.6 9.88a34.6 34.6 0 0013 4.07c-6.74-1.16-11.1-8.69-9-17 2.41-9.63 12.15-23.56 25.63-21.07z"
          />
          <path
            fill="#5a477d"
            d="M485.83 307.88v-.72-.73a25.89 25.89 0 00-8.83-18.77c2.82 2.9 9.1 13.84 2.28 22.17-4.49 5.49-25.78 13.31-43.3 3.26a18 18 0 005.51 4.67c10.13 5.86 26.59 5.86 36.75 0 4.76-2.76 7.29-6.29 7.59-9.88z"
          />
          <path
            fill="#a1727c"
            d="M459.6 287c1.16 2.65-1.07 6.19-5 7.91s-8 1-9.2-1.67 1.07-6.19 5-7.91 8.04-.95 9.2 1.67z"
            opacity=".5"
            style={{ mixBlendMode: "lighten" }}
          />
          <path
            fill="#926279"
            d="M444.55 297.8c.61 1.38-.27 3.1-2 3.84s-3.55.23-4.16-1.15.28-3.1 2-3.84 3.61-.23 4.16 1.15z"
            opacity=".5"
            style={{ mixBlendMode: "lighten" }}
          />
          <path
            fill="#443b6c"
            d="M441.5 317.76c10.13 5.86 26.59 5.86 36.75 0a18.24 18.24 0 005.49-4.67c-.06.06-8.07 7.82-23.83 7.82S436 313.09 436 313.09a18 18 0 005.5 4.67z"
          />
          <path
            fill="#73537b"
            d="M490.72 323.79a21.15 21.15 0 00-42.29 0 8.71 8.71 0 000 1.18c.24 2.93 2.31 5.8 6.19 8 8.25 4.77 21.66 4.77 29.93 0 3.86-2.25 5.93-5.12 6.17-8a8.71 8.71 0 000-1.18z"
          />
          <path
            fill="#996664"
            d="M478.75 305.33a21.12 21.12 0 00-30.32 18.46 8.71 8.71 0 000 1.18c.24 2.93 2.31 5.8 6.19 8a28.23 28.23 0 0010.61 3.31c-5.48-.95-9-7.07-7.35-13.84 1.96-7.79 9.89-19.14 20.87-17.11z"
            style={{ mixBlendMode: "lighten" }}
          />
          <path
            fill="#624b7d"
            d="M490.72 325a8.71 8.71 0 000-1.18 21 21 0 00-7.18-15.28c2.3 2.36 7.41 11.26 1.85 18.05-3.65 4.47-21 10.84-35.26 2.65a14.93 14.93 0 004.49 3.81c8.25 4.77 21.66 4.77 29.93 0 3.86-2.28 5.93-5.15 6.17-8.05z"
          />
          <path
            fill="#cca084"
            d="M469.36 308c.95 2.15-.87 5-4.06 6.44s-6.55.79-7.49-1.37.87-5 4.06-6.44 6.54-.8 7.49 1.37zM457.11 316.76a2.78 2.78 0 11-3.39-.94 2.51 2.51 0 013.39.94z"
            opacity=".5"
            style={{ mixBlendMode: "lighten" }}
          />
          <path
            fill="#4f3c68"
            d="M454.62 333c8.25 4.77 21.66 4.77 29.93 0a14.69 14.69 0 004.47-3.81c0 .05-6.57 6.37-19.41 6.37s-19.48-6.37-19.48-6.37a14.93 14.93 0 004.49 3.81z"
          />
          <path
            fill="#624b7c"
            d="M528.9 322.39a21.18 21.18 0 00-42.34 0v.59a5.72 5.72 0 000 .59c.24 2.94 2.3 5.81 6.2 8.06 8.26 4.78 21.69 4.78 30 0 3.87-2.25 5.94-5.12 6.17-8.06a5.72 5.72 0 000-.59c-.01-.2-.01-.38-.03-.59z"
          />
          <path
            fill="#855c75"
            d="M516.92 303.91a21 21 0 00-9.19-2.12 21.18 21.18 0 00-21.17 20.6v.59a5.72 5.72 0 000 .59c.24 2.94 2.3 5.81 6.2 8.06a28.11 28.11 0 0010.62 3.37c-5.49-.95-9.05-7.08-7.36-13.86 1.98-7.91 9.91-19.26 20.9-17.23z"
          />
          <path
            fill="#5a477d"
            d="M528.9 323.57a5.72 5.72 0 000-.59v-.59a21 21 0 00-7.18-15.3c2.3 2.36 7.41 11.28 1.85 18.08-3.66 4.47-21 10.85-35.31 2.65a14.72 14.72 0 004.5 3.81c8.26 4.78 21.69 4.78 30 0 3.84-2.25 5.91-5.12 6.14-8.06z"
          />
          <path
            fill="#a1727c"
            d="M507.52 306.57c.94 2.16-.88 5-4.07 6.45s-6.56.79-7.5-1.37.87-5 4.06-6.45 6.56-.79 7.51 1.37zM495.25 315.35a2.78 2.78 0 11-3.39-.94 2.53 2.53 0 013.39.94z"
            opacity=".5"
            style={{ mixBlendMode: "lighten" }}
          />
          <path
            fill="#4f3c68"
            d="M492.76 331.63c8.26 4.78 21.69 4.78 30 0a14.69 14.69 0 004.47-3.81c-.05.05-6.57 6.38-19.43 6.38s-19.51-6.38-19.51-6.38a14.72 14.72 0 004.47 3.81z"
          />
          <path
            fill="#bc7572"
            d="M443.93 294.13h-50.12a21.25 21.25 0 00-6.23 15V349h62.58v-39.84a21.25 21.25 0 00-6.23-15.03z"
          />
          <path
            fill="#e5bf7c"
            d="M410.78 297.31H427A16.71 16.71 0 01443.68 314v34.5h-49.62V314a16.71 16.71 0 0116.72-16.69z"
            style={{ mixBlendMode: "lighten" }}
          />
          <path
            fill="#f3eece"
            d="M416.11 300.62H421a13.17 13.17 0 0113.17 13.17V348h-31.23v-34.21a13.17 13.17 0 0113.17-13.17z"
          />
          <path
            fill="#d29b72"
            d="M443.79 293.43l-7.29-1.63-7.29 1.63a22.08 22.08 0 00.08 8.64c1 5.28 5.22 10.55 7.21 10.55s6.26-5.27 7.21-10.55a22.08 22.08 0 00.08-8.64z"
          />
          <path
            fill="#e8c275"
            d="M441.27 294.19h-10c-1.42 7.43 3.16 13.13 5 13.13s6.43-5.7 5-13.13z"
          />
          <path
            fill="#f3eece"
            d="M436.28 294.19H438a5.86 5.86 0 01.93 4c-.21 2-1.61 4.61-2.64 4.61s-2.43-2.61-2.63-4.61a5.89 5.89 0 01.92-4h1.71M433.3 302.55a.56.56 0 11.56.56.56.56 0 01-.56-.56zM435 304.94a1.24 1.24 0 112.48 0 1.24 1.24 0 01-2.48 0z"
          />
          <path
            fill="#b9787a"
            d="M441 290.08a3.47 3.47 0 001.87-3.15l-1.66-6.11-2.44 3.18.73 6.83a7.42 7.42 0 001.5-.75z"
          />
          <path
            fill="#e7bd88"
            d="M432 290.08a7.07 7.07 0 001.57.73l.73-6.84h-3.36l-.8 3a3.44 3.44 0 001.86 3.11z"
          />
          <path
            fill="#afa39a"
            d="M431.57 292.36a3.8 3.8 0 01-2-3.29l-.95 3.47a3.94 3.94 0 002.32 3.23 9.13 9.13 0 002 .84l.37-3.45a7.52 7.52 0 01-1.74-.8z"
          />
          <path
            fill="#806d60"
            d="M443.06 287.51l.08.29.34 1.28a3.82 3.82 0 01-2 3.28 7.71 7.71 0 01-1.69.79l.37 3.45a9.49 9.49 0 002-.83 3.92 3.92 0 002.32-3.23z"
          />
          <path
            fill="#675763"
            d="M442.9 286.93l.16.58-.16-.58zM430.11 286.93l-.24.87-.34 1.26.58-2.13z"
          />
          <path
            fill="#604242"
            d="M441 290.08a7.42 7.42 0 01-1.53.72l.25 2.35a7.71 7.71 0 001.69-.79 3.82 3.82 0 002-3.28l-.34-1.28-.08-.29-.16-.58a3.47 3.47 0 01-1.83 3.15z"
          />
          <path
            fill="#776471"
            d="M431.57 292.36a7.52 7.52 0 001.72.8l.25-2.35a7.07 7.07 0 01-1.57-.73 3.44 3.44 0 01-1.86-3.15l-.58 2.14a3.8 3.8 0 002.04 3.29z"
          />
          <path
            fill="#dd9f71"
            d="M439.5 290.8l-.73-6.83h-4.5l-.73 6.84a9.63 9.63 0 005.96-.01z"
          />
          <path
            fill="#917e6e"
            d="M433.29 293.16l-.37 3.45a13.24 13.24 0 007.2 0l-.37-3.45a10.48 10.48 0 01-6.46 0z"
          />
          <path
            fill="#675763"
            d="M433.54 290.81l-.25 2.35a10.48 10.48 0 006.46 0l-.25-2.35a9.63 9.63 0 01-5.96 0z"
          />
          <path
            fill="#e7bd88"
            d="M432.27 284h-1.36l-.8 3-.58 2.14-.95 3.47a3.94 3.94 0 002.32 3.23 9.13 9.13 0 002 .84 11.51 11.51 0 001.69.36.41.41 0 00.51-.36.62.62 0 00-.49-.53c-3.15-.83-4.69-2.72-4.52-3.45s1.77-7.11 2.18-8.7zM436.12 296.68c0-.24.29-.44.62-.45s.6.17.61.41-.26.46-.61.46-.62-.19-.62-.42z"
            style={{ mixBlendMode: "soft-light" }}
          />
          <path
            fill="#675763"
            d="M442.11 284l-.87-3.2-2.47 3.2h-7.86l-.8 3-.24.87-.34 1.27-.66 2.41c5.87-1.55 10.61-4.19 13.24-7.55z"
            opacity=".2"
            style={{ mixBlendMode: "multiply" }}
          />
          <path
            fill="#d29b72"
            d="M408.52 293.43l-7.29-1.63-7.29 1.63a22.08 22.08 0 00.08 8.64c1 5.28 5.22 10.55 7.21 10.55s6.26-5.27 7.21-10.55a21.84 21.84 0 00.08-8.64z"
          />
          <path
            fill="#e8c275"
            d="M406 294.19h-10c-1.42 7.43 3.16 13.13 5 13.13s6.43-5.7 5-13.13z"
          />
          <path
            fill="#f3eece"
            d="M401 294.19h1.71a5.86 5.86 0 01.93 4c-.21 2-1.61 4.61-2.64 4.61s-2.43-2.61-2.63-4.61a5.86 5.86 0 01.93-4h1.7M398 302.55a.56.56 0 11.56.56.56.56 0 01-.56-.56zM399.77 304.94a1.24 1.24 0 012.48 0 1.24 1.24 0 11-2.48 0z"
          />
          <path
            fill="#b9787a"
            d="M405.76 290.08a3.47 3.47 0 001.87-3.15l-1.63-6.11-2.5 3.18.73 6.83a7.42 7.42 0 001.53-.75z"
          />
          <path
            fill="#e7bd88"
            d="M396.7 290.08a7.07 7.07 0 001.57.73L399 284l-2.47-3.15-1.69 6.11a3.44 3.44 0 001.86 3.12z"
          />
          <path
            fill="#afa39a"
            d="M396.3 292.36a3.8 3.8 0 01-2-3.29l-.95 3.47a3.94 3.94 0 002.32 3.23 9.13 9.13 0 002 .84l.37-3.45a7.52 7.52 0 01-1.74-.8z"
          />
          <path
            fill="#806d60"
            d="M407.79 287.51l.08.29.34 1.28a3.82 3.82 0 01-2 3.28 7.71 7.71 0 01-1.69.79l.37 3.45a9.49 9.49 0 002-.83 3.92 3.92 0 002.32-3.23z"
          />
          <path
            fill="#675763"
            d="M407.63 286.93l.16.58-.16-.58zM394.84 286.93l-.24.87-.34 1.26.58-2.13z"
          />
          <path
            fill="#604242"
            d="M405.76 290.08a7.42 7.42 0 01-1.53.72l.25 2.35a7.71 7.71 0 001.69-.79 3.82 3.82 0 002-3.28l-.34-1.28-.08-.29-.16-.58a3.47 3.47 0 01-1.83 3.15z"
          />
          <path
            fill="#776471"
            d="M396.3 292.36a7.52 7.52 0 001.72.8l.25-2.35a7.07 7.07 0 01-1.57-.73 3.44 3.44 0 01-1.86-3.15l-.58 2.14a3.8 3.8 0 002.04 3.29z"
          />
          <path
            fill="#dd9f71"
            d="M404.23 290.8l-.73-6.8H399l-.73 6.84a9.63 9.63 0 005.96-.04z"
          />
          <path
            fill="#917e6e"
            d="M398 293.16l-.37 3.45a13.24 13.24 0 007.2 0l-.37-3.45a10.48 10.48 0 01-6.46 0z"
          />
          <path
            fill="#675763"
            d="M398.27 290.81l-.25 2.35a10.48 10.48 0 006.46 0l-.25-2.35a9.63 9.63 0 01-5.96 0z"
          />
          <path
            fill="#e7bd88"
            d="M397.52 282l-1-1.21-1.69 6.11-.58 2.14-.95 3.47a3.94 3.94 0 002.32 3.23 9.13 9.13 0 002 .84 11.37 11.37 0 001.7.36.41.41 0 00.5-.36.62.62 0 00-.49-.53c-3.15-.83-4.69-2.72-4.52-3.45s2.3-8.94 2.71-10.6zM400.86 296.68c0-.24.28-.44.61-.45a.53.53 0 01.61.41c0 .24-.26.46-.61.46s-.62-.19-.61-.42z"
            style={{ mixBlendMode: "soft-light" }}
          />
          <path
            fill="#675763"
            d="M396.53 280.82l-.88 3.17c2.62 3.35 7.34 6 13.21 7.48l-1.07-4-.16-.58-1.63-6.07-2.5 3.18H399l-1.49-1.9z"
            opacity=".2"
            style={{ mixBlendMode: "multiply" }}
          />
          <path
            fill="#d29b72"
            d="M428.59 299.61l-9.72-2.18-9.72 2.18a29.16 29.16 0 00.11 11.52c1.27 7 6.95 14.07 9.61 14.07s8.34-7 9.61-14.07a29.4 29.4 0 00.11-11.52z"
          />
          <path
            fill="#e8c275"
            d="M425.23 300.62h-13.31c-1.9 9.91 4.21 17.51 6.66 17.51s8.55-7.6 6.65-17.51z"
          />
          <path
            fill="#f3eece"
            d="M418.58 300.62h2.28a7.77 7.77 0 011.23 5.29c-.27 2.67-2.14 6.15-3.51 6.15s-3.24-3.48-3.52-6.15a7.81 7.81 0 011.24-5.29h2.28M414.6 311.77a.75.75 0 11.75.75.76.76 0 01-.75-.75zM416.92 315a1.66 1.66 0 111.66 1.65 1.66 1.66 0 01-1.66-1.65z"
          />
          <path
            fill="#b9787a"
            d="M424.91 294.43c1.8-1.15 2.63-2.69 2.49-4.2l-1.07-4h-4.44l1 9.12a9.34 9.34 0 002.02-.92z"
          />
          <path
            fill="#e7bd88"
            d="M412.83 294.43a9.47 9.47 0 002.08 1l1-9.13h-4.48l-1.07 4c-.16 1.44.64 2.98 2.47 4.13z"
          />
          <path
            fill="#afa39a"
            d="M412.29 297.47c-1.89-1.21-2.8-2.8-2.72-4.39l-1.26 4.63c0 1.56 1 3.12 3.09 4.31a12 12 0 002.69 1.12l.49-4.59a10.25 10.25 0 01-2.29-1.08z"
          />
          <path
            fill="#806d60"
            d="M427.61 291l.1.39.46 1.7a5.08 5.08 0 01-2.72 4.37 9.89 9.89 0 01-2.25 1.06l.49 4.6a12 12 0 002.64-1.11c2.06-1.19 3.09-2.75 3.09-4.31z"
          />
          <path
            fill="#675763"
            d="M427.4 290.23l.21.78-.21-.78zM410.34 290.23l-.32 1.17-.46 1.68.78-2.85z"
          />
          <path
            fill="#604242"
            d="M424.91 294.43a9.34 9.34 0 01-2 1l.33 3.13a9.89 9.89 0 002.25-1.06 5.08 5.08 0 002.72-4.37l-.46-1.7-.1-.39-.21-.78c.1 1.48-.73 3.02-2.53 4.17z"
          />
          <path
            fill="#776471"
            d="M412.29 297.47a10.25 10.25 0 002.29 1.08l.33-3.14a9.47 9.47 0 01-2.08-1c-1.8-1.15-2.63-2.69-2.49-4.2l-.77 2.85c-.08 1.61.83 3.2 2.72 4.41z"
          />
          <path
            fill="#dd9f71"
            d="M422.87 295.4l-1-9.12h-6l-1 9.13a13 13 0 008-.01z"
          />
          <path
            fill="#917e6e"
            d="M414.58 298.55l-.49 4.59a17.59 17.59 0 009.6 0l-.49-4.6a14.1 14.1 0 01-8.62.01z"
          />
          <path
            fill="#675763"
            d="M414.91 295.41l-.33 3.14a14.1 14.1 0 008.62 0l-.33-3.13a13 13 0 01-7.96-.01z"
          />
          <path
            fill="#e7bd88"
            d="M413.22 286.28h-1.81l-1.07 4-.77 2.85-1.26 4.63c0 1.56 1 3.12 3.09 4.31a12 12 0 002.69 1.12 15.37 15.37 0 002.26.48.55.55 0 00.68-.48.85.85 0 00-.67-.71c-4.19-1.1-6.24-3.63-6-4.6s2.32-9.43 2.86-11.6zM418.36 303.23a.8.8 0 01.82-.6.72.72 0 01.82.55c0 .32-.35.61-.82.62s-.83-.26-.82-.57z"
            style={{ mixBlendMode: "soft-light" }}
          />
          <path
            fill="#675763"
            d="M418.85 292.65a43.29 43.29 0 008.95-.91l-.09-.34-.1-.39-.21-.78-1.07-4h-14.92l-1.07 4-.41 1.52a43.71 43.71 0 008.92.9z"
            opacity=".2"
            style={{ mixBlendMode: "multiply" }}
          />
          <path
            fill="#d68d97"
            d="M444.2 249.64V276.42c0 .15-.05.3-.08.44a1.89 1.89 0 01-.05.23c0 .17-.08.33-.13.49a10.44 10.44 0 01-.37 1l-.1.23c-.07.15-.14.3-.22.45a.9.9 0 01-.05.09 10.22 10.22 0 01-1.11 1.72l-.46.57c-.25.28-.51.56-.78.83l-.72.66-.17.15c-.25.22-.52.43-.8.64l-.2.16c-.26.19-.53.37-.8.55l-.21.14a6 6 0 01-.53.34c-.17.12-.36.23-.55.34-.34.19-.7.39-1.06.57h-.06c-.35.19-.71.36-1.08.52l-1 .45-.36.15-.91.34h-.1l-1.3.52c-9.63 3-21.94 2.2-30.09-2.5-4.95-2.86-7.43-6.61-7.43-10.35v-25.5z"
          />
          <path
            fill="#d28088"
            d="M418.89 283.11c0 3.68-3.27 6.24-12.68 4.72a42 42 0 0025.36 0V262.3l-12.68 2z"
          />
          <path
            fill="#e1afb9"
            d="M406.21 249.65H393.5v25.48c0 3.75 2.48 7.5 7.43 10.36a29 29 0 005.28 2.34z"
          />
          <path
            fill="#eacbd2"
            d="M396.53 271.93c0 2.92 1.49 6.14 3.33 7.2s3.32-.44 3.32-3.36v-21.86l-6.65-3.85z"
          />
          <path
            fill="#ce757a"
            d="M436.8 260a28.45 28.45 0 01-5.23 2.3v18.57c0 3.72-10.35 9.47-21.67 7.95 9 2 19.63.87 26.9-3.33.38-.22.74-.45 1.09-.67v-25.49c-.35.23-.71.45-1.09.67z"
          />
          <path
            fill="#c7686c"
            d="M444.2 250.3V276.42c0 .15-.05.3-.08.44a1.89 1.89 0 01-.05.23c0 .17-.08.33-.13.49a10.44 10.44 0 01-.37 1l-.1.23c-.07.15-.14.3-.22.45a.9.9 0 01-.05.09 11.65 11.65 0 01-1.11 1.72l-.46.57a17.58 17.58 0 01-3.68 3.13 6 6 0 01-.53.34c-.17.12-.36.23-.55.34-.34.19-.7.39-1.06.57h-.06c-.35.19-.71.36-1.08.52l-1 .45-.36.15-.91.34h-.1l-1.3.52a39.59 39.59 0 01-3.86 1c9.28-3.12 10.74-8.3 10.74-12.23v-17.4c3.96-2.63 6.12-5.83 6.32-9.07z"
          />
          <path
            fill="#a8c0e3"
            d="M393.5 239.78v9.87c0 3.74 2.48 7.49 7.43 10.35a29 29 0 005.28 2.32v-22.54z"
          />
          <path
            fill="#d3e8f7"
            d="M403.18 239.78h-6.65v16.8a18.91 18.91 0 004.4 3.42c.72.41 1.48.79 2.25 1.14z"
          />
          <path
            fill="#7e9acc"
            d="M406.21 239.78v22.54a42 42 0 0025.36 0v-22.54z"
          />
          <path
            fill="#7591c5"
            d="M418.89 264.27a40.41 40.41 0 0012.68-2v-22.49h-12.68z"
          />
          <path
            fill="#6c85bd"
            d="M437.89 259.33v-9.68h-.28l-.81.49a29.14 29.14 0 01-5.23 2.3v9.86a28.45 28.45 0 005.23-2.3c.38-.22.74-.44 1.09-.67z"
          />
          <path
            fill="#5f71ae"
            d="M437.89 249.46v9.87c4-2.59 6.06-5.79 6.31-9v-9.86c-.2 3.21-2.35 6.41-6.31 8.99z"
          />
          <path
            fill="#d3e8f7"
            d="M393.5 229.92v9.86c0 3.75 2.48 7.5 7.43 10.36a29 29 0 005.28 2.31v-22.53z"
          />
          <path
            fill="#f1f8fc"
            d="M403.18 229.92h-6.65v16.8a19.18 19.18 0 004.4 3.42c.72.41 1.48.78 2.25 1.13z"
          />
          <path
            fill="#a8c0e3"
            d="M406.21 229.92v22.53a42 42 0 0025.36 0v-22.53z"
          />
          <path
            fill="#9eb7dd"
            d="M418.89 254.41a40.41 40.41 0 0012.68-2v-22.49h-12.68z"
          />
          <path
            fill="#7e9acc"
            d="M437.61 239.78c-.26.17-.53.33-.81.49a28.16 28.16 0 01-5.23 2.3v9.87a29.14 29.14 0 005.23-2.3l.81-.49.28-.19v-9.68z"
          />
          <path
            fill="#6c85bd"
            d="M437.89 239.6v9.86c4-2.58 6.06-5.78 6.31-9v-9.87c-.2 3.22-2.35 6.41-6.31 9.01z"
          />
          <path
            fill="#a8c0e3"
            d="M393.5 220.05v9.87c0 3.74 2.48 7.49 7.43 10.35a29 29 0 005.28 2.32v-22.54z"
          />
          <path
            fill="#d3e8f7"
            d="M403.18 220.05h-6.65v16.8a18.91 18.91 0 004.4 3.42c.72.41 1.48.79 2.25 1.14z"
          />
          <path
            fill="#7e9acc"
            d="M406.21 220.05v22.54a42 42 0 0025.36 0v-22.54z"
          />
          <path
            fill="#7591c5"
            d="M418.89 244.54a40.41 40.41 0 0012.68-2v-22.49h-12.68z"
          />
          <path
            fill="#6c85bd"
            d="M437.61 229.92l-.81.49a29.19 29.19 0 01-5.23 2.32v9.84a28.16 28.16 0 005.23-2.3c.28-.16.55-.32.81-.49l.28-.18v-9.86z"
          />
          <path
            fill="#5f71ae"
            d="M444.2 220.05c0 3.45-2.09 6.9-6.31 9.67v9.86c4-2.59 6.06-5.79 6.31-9v-10.53z"
          />
          <path
            fill="#f5e5c2"
            d="M393.5 137.83v82.22c0 3.75 2.48 7.5 7.43 10.36a28.34 28.34 0 005.28 2.33v-82.23z"
          />
          <path
            fill="#fcf7ed"
            d="M403.18 147.5l-6.65-6.65V227a18.91 18.91 0 004.4 3.42c.72.41 1.48.79 2.25 1.14z"
          />
          <path
            fill="#dea177"
            d="M431.57 150.51v82.22a29.19 29.19 0 005.23-2.32c4.95-2.86 7.43-6.61 7.4-10.34v-82.24z"
          />
          <path
            fill="#edcf8e"
            d="M431.57 150.51v82.22l-.2.07a40.12 40.12 0 01-11 1.87h-3a40.13 40.13 0 01-10.91-1.84 1.84 1.84 0 01-.25-.09v-82.23z"
          />
          <path
            fill="#e9c488"
            d="M425.22 158.85a6.35 6.35 0 00-6.35 6.35v39.1a6.35 6.35 0 006.35 6.36 6.35 6.35 0 006.35-6.36v-39.1a6.35 6.35 0 00-6.35-6.35zM425.22 217.79a6.35 6.35 0 00-6.35 6.35v.24a6.35 6.35 0 006.35 6.35 6.35 6.35 0 006.35-6.35v-.24a6.35 6.35 0 00-6.35-6.35z"
          />
          <path
            fill="#ce8a74"
            d="M437.89 144.19v85.55c4.22-2.77 6.34-6.22 6.31-9.67v-82.21z"
          />
          <path
            fill="#7e9acc"
            d="M418.87 225.74a5.52 5.52 0 01-5.52-5.52v-8.31a5.52 5.52 0 015.52-5.52 5.52 5.52 0 015.51 5.52v8.31a5.52 5.52 0 01-5.51 5.52z"
          />
          <path
            fill="#7591c5"
            d="M424.38 220.22v-8.31a5.52 5.52 0 00-5.51-5.52v19.35a5.52 5.52 0 005.51-5.52z"
          />
          <text
            fill="#fcf7ed"
            fontFamily="AsapCondensed-SemiBold,Asap Condensed"
            fontSize="15.5"
            fontWeight={600}
            transform="translate(415.31 221.62)"
          >
            2
          </text>
          <path
            fill="#5f71ae"
            d="M463 276.22v29.46a1.65 1.65 0 01-1.64 1.64 1.73 1.73 0 01-1.72-1.55v-.09c0-1.45-.15-2.88-.32-4.31-.06-.47-.12-.95-.19-1.42s-.11-.67-.17-1-.06-.41-.1-.62c-.07-.36-.13-.72-.21-1.08a.53.53 0 000-.12c-.07-.39-.16-.79-.25-1.17 0-.15-.07-.28-.1-.42s-.1-.44-.16-.66-.17-.68-.27-1a3 3 0 00-.09-.32c-.12-.44-.25-.87-.39-1.31l-.33-1c-.09-.25-.17-.5-.27-.75s-.26-.72-.39-1.07-.29-.76-.45-1.13c-.41-1-.86-2-1.35-3-.22-.45-.45-.9-.68-1.35s-.48-.9-.72-1.33c-.5-.87-1-1.73-1.56-2.58s-1.12-1.69-1.72-2.5c-.89-1.22-1.85-2.41-2.85-3.55a50.6 50.6 0 00-9.17-8.14c-4.59-9.37-3.82-19.64 0-30.44a45.85 45.85 0 0125.1 40.81z"
          />
          <path
            fill="#4f5ea1"
            d="M461.37 276c-.65.83-1.39-.63-2-3.46L454 284a49.46 49.46 0 011.97 4.22c.16.37.31.75.45 1.13s.27.71.39 1.07.18.5.27.75l.33 1c.14.44.27.87.39 1.31a3 3 0 01.09.32c.1.33.19.67.27 1s.11.44.16.66.07.27.1.42c.09.38.18.78.25 1.17a.53.53 0 010 .12c.08.36.14.72.21 1.08 0 .21.08.41.1.62s.12.68.17 1 .13.95.19 1.42c.17 1.43.28 2.86.32 4.31v.09a1.73 1.73 0 001.72 1.55 1.65 1.65 0 001.64-1.64v-29.38a46.25 46.25 0 00-1-9.52c.67 3.8.25 8.18-.65 9.3z"
          />
          <path
            fill="#697eb8"
            d="M461.37 298.11v9.21a1.73 1.73 0 01-1.72-1.55v-.09c0-1.45-.16-2.89-.32-4.31-.06-.47-.13-.95-.19-1.42s-.11-.67-.17-1-.06-.41-.1-.62c-.07-.36-.13-.72-.21-1.08a.53.53 0 000-.12c-.07-.39-.16-.79-.25-1.17 0-.15-.07-.28-.1-.42s-.1-.44-.16-.66-.17-.68-.27-1a3 3 0 00-.09-.32c-.12-.44-.25-.88-.39-1.31s-.22-.7-.33-1-.17-.5-.27-.75-.26-.72-.39-1.07-.29-.76-.45-1.13c-.41-1-.86-2-1.35-3-.22-.45-.44-.9-.68-1.35s-.48-.9-.72-1.33q-.75-1.32-1.56-2.58c-.55-.86-1.12-1.69-1.72-2.5a53.97 53.97 0 00-2.85-3.55 50.6 50.6 0 00-9.17-8.14c-4.59-9.37-3.82-19.64 0-30.44 9.48 9.86 23.46 24.33 23.46 62.7z"
          />
          <path
            fill="#a8c0e3"
            d="M461.37 298.11s-.08-1.07-.2-3c-.05-.95-.12-2.09-.2-3.41s-.29-2.78-.42-4.38c-.08-.81-.16-1.64-.25-2.51s-.25-1.75-.38-2.66-.27-1.85-.41-2.81-.36-1.93-.54-2.92-.38-2-.6-3-.5-2-.76-3c-.12-.52-.25-1-.37-1.55s-.31-1-.47-1.52c-.32-1-.63-2-.95-3.07-.76-2-1.42-4-2.33-5.94s-1.77-3.77-2.69-5.56l-1.49-2.55c-.47-.85-1-1.61-1.51-2.39-.26-.39-.49-.78-.75-1.14s-.52-.72-.78-1.07c-.5-.71-1-1.41-1.48-2l-1.42-1.83-1.32-1.62c-.82-1-1.6-1.87-2.21-2.59l-1.94-2.25.56.54c.36.35.91.85 1.58 1.53s1.49 1.47 2.39 2.43c.45.48.93 1 1.44 1.53l1.53 1.77c.55.6 1.05 1.29 1.6 2s1.11 1.41 1.63 2.18 1.12 1.53 1.63 2.37l1.58 2.56c1 1.8 2 3.64 2.85 5.62s1.63 4 2.42 6c.31 1 .63 2.08.94 3.12.15.52.32 1 .46 1.56s.25 1 .37 1.56c.25 1.05.49 2.08.73 3.1s.35 2.06.53 3.06.36 2 .47 3 .21 1.93.31 2.85.19 1.83.28 2.71.08 1.72.12 2.53c.07 1.63.16 3.12.16 4.43s0 2.47-.05 3.41c-.02 1.8-.06 2.91-.06 2.91z"
          />
          <path
            fill="#5f71ae"
            d="M442.9 264.27c-4.72-4.38-8.07-14.48-7.86-17.37a32.73 32.73 0 002.84 18.85 50.54 50.54 0 0121.8 41.57c.14-27.93-12.16-38.76-16.78-43.05z"
          />
          <path
            fill="#7e9acc"
            d="M438.36 239.6c-1.57 2.36-.73 12.72 2.38 17.35s8.57 9.15 12.16 15c2.67 4.35 4.1 1.34 2.69-3.19s-3.18-11.68-7.85-18.88-8.74-11.17-9.38-10.28z"
            opacity=".7"
          />
          <path
            fill="#9eb7dd"
            d="M440.48 242c-1.24-1-1.11 3.2-.47 5.59s8.39 11.25 9.21 11.77.42-2.77-2.31-7.83c-2.54-4.75-4.59-8.04-6.43-9.53z"
            opacity=".5"
          />
          <path
            fill="#5f71ae"
            d="M374.72 276.22v29.46a1.65 1.65 0 001.64 1.64 1.72 1.72 0 001.72-1.55.28.28 0 010-.09c0-1.45.15-2.88.32-4.31 0-.47.11-.95.19-1.42s.1-.67.16-1 .06-.41.1-.62l.21-1.08v-.12c.07-.39.16-.79.26-1.17 0-.15.06-.28.09-.42s.1-.44.17-.66.17-.68.26-1l.09-.32c.12-.44.25-.87.39-1.31s.22-.7.34-1 .17-.5.26-.75.26-.72.39-1.07.29-.76.45-1.13c.41-1 .86-2 1.35-3 .22-.45.45-.9.68-1.35s.48-.9.72-1.33c.5-.87 1-1.73 1.57-2.58s1.12-1.69 1.71-2.5c.89-1.22 1.85-2.41 2.86-3.55a50.21 50.21 0 019.16-8.14c4.59-9.37 3.82-19.64 0-30.44a45.85 45.85 0 00-25.09 40.81z"
          />
          <path
            fill="#4f5ea1"
            d="M376.36 276c.65.83 1.39-.63 2-3.46l5.37 11.4a49.46 49.46 0 00-1.97 4.22c-.16.37-.3.75-.45 1.13s-.27.71-.39 1.07-.18.5-.26.75-.23.69-.34 1-.27.87-.39 1.31l-.09.32c-.09.33-.18.67-.26 1s-.12.44-.17.66-.07.27-.09.42c-.1.38-.19.78-.26 1.17v.12l-.21 1.08c0 .21-.07.41-.1.62s-.12.68-.16 1-.14.95-.19 1.42c-.17 1.43-.28 2.86-.32 4.31a.28.28 0 000 .09 1.72 1.72 0 01-1.72 1.55 1.65 1.65 0 01-1.64-1.64v-29.32a46.25 46.25 0 011-9.52c-.72 3.8-.26 8.18.64 9.3z"
          />
          <path
            fill="#697eb8"
            d="M376.36 298.11v9.21a1.72 1.72 0 001.72-1.55.28.28 0 010-.09c0-1.45.15-2.89.32-4.31.06-.47.12-.95.19-1.42s.1-.67.16-1 .07-.41.1-.62l.21-1.08v-.12c.07-.39.16-.79.26-1.17 0-.15.06-.28.09-.42s.1-.44.17-.66.17-.68.26-1l.09-.32c.12-.44.25-.88.39-1.31s.22-.7.34-1 .17-.5.26-.75.26-.72.39-1.07.29-.76.45-1.13c.41-1 .86-2 1.35-3 .22-.45.45-.9.68-1.35s.48-.9.72-1.33c.51-.88 1-1.74 1.57-2.58s1.12-1.69 1.71-2.5c.9-1.22 1.85-2.41 2.86-3.55a50.21 50.21 0 019.16-8.14c4.59-9.37 3.82-19.64 0-30.44-9.46 9.86-23.45 24.33-23.45 62.7z"
          />
          <path
            fill="#a8c0e3"
            d="M376.36 298.11s0-1.08-.06-3v-3.41c0-1.32.08-2.8.15-4.43 0-.81.08-1.66.12-2.53s.19-1.78.28-2.71.2-1.88.31-2.85.31-1.95.47-3 .34-2 .53-3.06.48-2.05.73-3.1c.12-.52.25-1 .37-1.56s.31-1 .46-1.56c.32-1 .63-2.08.95-3.12.78-2 1.47-4.11 2.41-6s1.89-3.82 2.85-5.62c.54-.87 1.07-1.72 1.58-2.56s1.1-1.6 1.63-2.37 1.08-1.49 1.63-2.18 1.06-1.37 1.6-2l1.53-1.77 1.45-1.53c.89-1 1.73-1.76 2.39-2.43s1.21-1.18 1.57-1.53l.56-.54-1.94 2.25c-.61.72-1.38 1.57-2.21 2.59l-1.32 1.62-1.42 1.83c-.51.62-1 1.32-1.48 2-.26.35-.51.7-.77 1.07s-.5.75-.75 1.14c-.49.78-1.05 1.54-1.52 2.39l-1.49 2.55c-.91 1.79-1.88 3.62-2.69 5.56s-1.57 4-2.33 5.94c-.31 1-.63 2.05-1 3.07-.15.51-.32 1-.46 1.52s-.26 1-.38 1.55c-.26 1-.51 2-.76 3s-.4 2-.59 3-.4 2-.55 2.92-.27 1.89-.4 2.81-.26 1.8-.39 2.66-.16 1.7-.24 2.51c-.14 1.6-.34 3.07-.43 4.38s-.14 2.46-.2 3.41c-.1 1.98-.19 3.09-.19 3.09z"
          />
          <path
            fill="#5f71ae"
            d="M394.83 264.27c4.72-4.38 8.08-14.48 7.86-17.37a32.73 32.73 0 01-2.84 18.85 50.54 50.54 0 00-21.8 41.57c-.13-27.93 12.16-38.76 16.78-43.05z"
          />
          <path
            fill="#7e9acc"
            d="M399.37 239.6c1.57 2.36.74 12.72-2.38 17.35s-8.57 9.15-12.16 15c-2.67 4.35-4.1 1.34-2.68-3.19s3.18-11.68 7.84-18.88 8.79-11.17 9.38-10.28z"
            opacity=".7"
          />
          <path
            fill="#9eb7dd"
            d="M397.25 242c1.24-1 1.12 3.2.48 5.59s-8.39 11.25-9.21 11.77-.42-2.77 2.3-7.83c2.55-4.75 4.59-8.04 6.43-9.53z"
            opacity=".5"
          />
          <path
            fill="#fbf3e5"
            d="M420.75 82.69a3.28 3.28 0 00-1.88-.59 3.33 3.33 0 00-1.89.59c-.91.63-2 2.87-2.86 4.52-4.72 8.67-18.14 34.36-20.62 50.62l12.71 12.68h25.36l12.66-12.68c-2.47-16.26-15.89-42-20.61-50.62-.9-1.64-1.96-3.89-2.87-4.52z"
          />
          <path
            fill="#f2e5d1"
            d="M444.22 137.81c-2.48-16.27-15.88-41.93-20.6-50.6-.9-1.64-2-3.89-2.87-4.52a3.28 3.28 0 00-1.88-.59c2.61 4.19 12.7 32.37 12.7 68.41l12.63-12.65z"
          />
          <path
            fill="#fdfaf3"
            d="M417.23 85.59c.9-2.52 4.26-2.47 5.52 0a9.56 9.56 0 00-2-2.9 3.28 3.28 0 00-1.88-.59 3.33 3.33 0 00-1.89.59c-.91.63-2 2.87-2.86 4.52-4.72 8.67-18.14 34.36-20.62 50.62l12.71 12.68s.17-18.42 1.62-29.89c2.02-15.93 8.5-32.52 9.4-35.03z"
          />
          <path
            fill="#614d43"
            d="M418.89 106.63a44.15 44.15 0 0013.55-2c-3.5-7.44-6.89-13.84-8.82-17.39-.9-1.64-2-3.89-2.87-4.52a3.28 3.28 0 00-1.88-.59 3.33 3.33 0 00-1.89.59c-.91.63-2 2.87-2.86 4.52-1.93 3.55-5.32 9.94-8.82 17.37a43.75 43.75 0 0013.59 2.02z"
          />
          <path
            fill="#4b3c36"
            d="M432.44 104.6c-3.5-7.44-6.89-13.84-8.82-17.39-.9-1.64-2-3.89-2.87-4.52a3.28 3.28 0 00-1.88-.59c1.36 2.19 4.76 10.93 7.7 23.91a40.69 40.69 0 005.87-1.41z"
          />
          <path
            fill="#74626e"
            d="M422.75 85.59a9.56 9.56 0 00-2-2.9 3.28 3.28 0 00-1.88-.59 3.33 3.33 0 00-1.89.59c-.91.63-2 2.87-2.86 4.52-1.93 3.55-5.32 9.94-8.82 17.37a38.48 38.48 0 005.43 1.35 206.85 206.85 0 016.5-20.34c.9-2.52 4.26-2.47 5.52 0z"
          />
          <path
            fill="#53447c"
            d="M553.63 328.48a12.91 12.91 0 00-25.81 0v.72c.15 1.78 1.41 3.54 3.78 4.91 5 2.91 13.22 2.91 18.26 0 2.36-1.37 3.62-3.13 3.77-4.91v-.36c0-.12.01-.24 0-.36z"
          />
          <path
            fill="#6a507e"
            d="M546.33 317.21a12.89 12.89 0 00-18.51 11.27v.72c.15 1.78 1.41 3.54 3.78 4.91a17.29 17.29 0 006.48 2c-3.35-.58-5.52-4.32-4.49-8.45 1.19-4.77 6.04-11.66 12.74-10.45z"
          />
          <path
            fill="#4b3d6e"
            d="M553.63 329.2v-.36-.36a12.87 12.87 0 00-4.38-9.33c1.4 1.44 4.52 6.87 1.13 11-2.23 2.72-12.81 6.61-21.52 1.61a8.83 8.83 0 002.74 2.33c5 2.91 13.22 2.91 18.26 0 2.36-1.35 3.62-3.09 3.77-4.89z"
          />
          <path
            fill="#7e587b"
            d="M540.59 318.83c.58 1.32-.53 3.08-2.48 3.93s-4 .48-4.57-.83.53-3.08 2.48-3.93 3.98-.48 4.57.83zM533.12 324.19a1.7 1.7 0 11-2.07-.58 1.54 1.54 0 012.07.58z"
            opacity=".5"
            style={{ mixBlendMode: "lighten" }}
          />
          <path
            fill="#3b335f"
            d="M531.6 334.11c5 2.91 13.22 2.91 18.26 0a9 9 0 002.73-2.32s-4 3.88-11.84 3.88-11.89-3.89-11.89-3.89a8.83 8.83 0 002.74 2.33z"
          />
          <path
            fill="#5b497e"
            d="M533.88 338.18a17.78 17.78 0 00-35.54 0v.99c.21 2.46 1.94 4.87 5.21 6.76 6.93 4 18.2 4 25.15 0 3.25-1.89 5-4.3 5.18-6.76a4.18 4.18 0 000-.5 4 4 0 000-.49z"
          />
          <path
            fill="#6a507e"
            d="M523.83 322.66a17.77 17.77 0 00-25.49 15.52v.99c.21 2.46 1.94 4.87 5.21 6.76a23.47 23.47 0 008.91 2.78c-4.61-.79-7.59-5.94-6.17-11.63 1.64-6.59 8.31-16.08 17.54-14.42z"
          />
          <path
            fill="#4f4176"
            d="M533.88 339.17a4.18 4.18 0 000-.5 4 4 0 000-.49 17.66 17.66 0 00-6-12.85c1.93 2 6.23 9.47 1.56 15.17-3.07 3.76-17.64 9.11-29.64 2.23a12.37 12.37 0 003.78 3.2c6.93 4 18.2 4 25.15 0 3.27-1.93 4.95-4.3 5.15-6.76z"
          />
          <ellipse
            cx="511.08"
            cy="327.03"
            fill="#7e587b"
            opacity=".5"
            rx="5.3"
            ry="3.58"
            style={{ mixBlendMode: "lighten" }}
            transform="rotate(-23.71 511.074 327.023)"
          />
          <path
            fill="#7e587b"
            d="M505.64 332.27a2.34 2.34 0 11-2.85-.79 2.12 2.12 0 012.85.79z"
            opacity=".5"
            style={{ mixBlendMode: "lighten" }}
          />
          <path
            fill="#3b335f"
            d="M503.55 345.93c6.93 4 18.2 4 25.15 0a12.23 12.23 0 003.75-3.2s-5.51 5.36-16.31 5.36-16.37-5.36-16.37-5.36a12.37 12.37 0 003.78 3.2z"
          />
          <path
            fill="#6a4f7c"
            d="M502.19 344.49a22.06 22.06 0 00-44.1 0v1.23c.25 3.06 2.4 6 6.45 8.4 8.61 5 22.59 5 31.22 0 4-2.35 6.19-5.34 6.43-8.4v-.61c0-.21.02-.4 0-.62z"
          />
          <path
            fill="#7e587b"
            d="M489.71 325.24a22 22 0 00-31.62 19.25v1.23c.25 3.06 2.4 6 6.45 8.4a29.35 29.35 0 0011.07 3.45c-5.72-1-9.43-7.38-7.67-14.43 2.06-8.14 10.32-20.02 21.77-17.9z"
            style={{ mixBlendMode: "lighten" }}
          />
          <path
            fill="#5a477d"
            d="M502.19 345.72v-.61-.62a21.94 21.94 0 00-7.48-15.94c2.39 2.47 7.72 11.75 1.93 18.83-3.81 4.66-21.89 11.31-36.78 2.77a15.41 15.41 0 004.68 4c8.61 5 22.59 5 31.22 0 4.03-2.38 6.24-5.37 6.43-8.43z"
          />
          <path
            fill="#926279"
            d="M479.92 328c1 2.25-.91 5.26-4.24 6.72s-6.83.82-7.81-1.43.91-5.25 4.23-6.72 6.83-.81 7.82 1.43zM467.14 337.16a2.9 2.9 0 11-3.53-1 2.63 2.63 0 013.53 1z"
            opacity=".5"
            style={{ mixBlendMode: "lighten" }}
          />
          <path
            fill="#443b6c"
            d="M464.54 354.12c8.61 5 22.59 5 31.22 0a15.31 15.31 0 004.66-4c-.05.05-6.85 6.65-20.24 6.65s-20.32-6.65-20.32-6.65a15.41 15.41 0 004.68 4z"
          />
          <path
            fill="#4b3d6e"
            d="M564.55 331.5a7.8 7.8 0 00-15.59 0 1.62 1.62 0 000 .22 1.7 1.7 0 000 .22 4 4 0 002.28 3 12.2 12.2 0 0011 0 4 4 0 002.27-3 1.7 1.7 0 000-.22 1.62 1.62 0 00.04-.22z"
          />
          <path
            fill="#53447c"
            d="M560.14 324.69A7.79 7.79 0 00549 331.5a1.62 1.62 0 000 .22 1.7 1.7 0 000 .22 4 4 0 002.28 3 10.38 10.38 0 003.91 1.23c-2-.35-3.33-2.61-2.71-5.11.68-2.93 3.61-7.06 7.66-6.37z"
          />
          <path
            fill="#3b335f"
            d="M564.55 331.94a1.7 1.7 0 000-.22 1.62 1.62 0 000-.22 7.79 7.79 0 00-2.64-5.64c.84.88 2.73 4.16.68 6.66-1.35 1.65-7.74 4-13 1a5.47 5.47 0 001.66 1.4 12.2 12.2 0 0011 0 4 4 0 002.3-2.98z"
          />
          <path
            fill="#7e587b"
            d="M556.68 325.67c.35.8-.33 1.86-1.5 2.38s-2.42.29-2.77-.51.33-1.85 1.5-2.37 2.42-.29 2.77.5zM552.16 328.91a1 1 0 01-1.84.81 1 1 0 011.84-.81z"
            opacity=".5"
            style={{ mixBlendMode: "lighten" }}
          />
          <path
            fill="#312a53"
            d="M551.24 334.9a12.2 12.2 0 0011 0 5.3 5.3 0 001.65-1.4 10.62 10.62 0 01-7.16 2.35 10.75 10.75 0 01-7.19-2.35 5.47 5.47 0 001.7 1.4z"
          />
          <path
            fill="#53447c"
            d="M291.27 324.2a12.91 12.91 0 0125.81 0v.72c-.15 1.79-1.41 3.54-3.78 4.91-5 2.91-13.22 2.91-18.26 0-2.36-1.37-3.62-3.12-3.77-4.91v-.36c0-.12-.01-.24 0-.36z"
          />
          <path
            fill="#6a507e"
            d="M298.57 312.93a12.89 12.89 0 0118.51 11.27v.72c-.15 1.79-1.41 3.54-3.78 4.91a17.12 17.12 0 01-6.47 2c3.34-.58 5.51-4.32 4.48-8.44-1.19-4.77-6.03-11.7-12.74-10.46z"
          />
          <path
            fill="#4b3d6e"
            d="M291.27 324.92v-.36-.36a12.87 12.87 0 014.38-9.33c-1.4 1.44-4.52 6.88-1.13 11 2.23 2.72 12.81 6.61 21.52 1.62a9 9 0 01-2.74 2.32c-5 2.91-13.22 2.91-18.26 0-2.36-1.35-3.62-3.1-3.77-4.89z"
          />
          <path
            fill="#7e587b"
            d="M304.31 314.55c-.58 1.32.53 3.08 2.48 3.93s4 .49 4.57-.83-.53-3.08-2.48-3.93-4-.48-4.57.83zM311.78 319.91a1.7 1.7 0 102.07-.57 1.54 1.54 0 00-2.07.57z"
            opacity=".5"
            style={{ mixBlendMode: "lighten" }}
          />
          <path
            fill="#3b335f"
            d="M313.3 329.83c-5 2.91-13.22 2.91-18.26 0a9 9 0 01-2.73-2.32s4 3.89 11.84 3.89 11.85-3.89 11.85-3.89a9 9 0 01-2.7 2.32z"
          />
          <path
            fill="#4b3d6e"
            d="M291.94 297.85a7.46 7.46 0 0114.92 0v.41a3.86 3.86 0 01-2.19 2.84 11.67 11.67 0 01-10.55 0 3.85 3.85 0 01-2.18-2.84v-.41z"
          />
          <path
            fill="#53447c"
            d="M296.16 291.34a7.35 7.35 0 013.24-.75 7.47 7.47 0 017.46 7.26v.41a3.86 3.86 0 01-2.19 2.84 10.07 10.07 0 01-3.74 1.17c1.94-.34 3.19-2.5 2.59-4.88-.69-2.77-3.52-6.77-7.36-6.05z"
          />
          <path
            fill="#3b335f"
            d="M291.94 298.26v-.41a7.44 7.44 0 012.53-5.39c-.81.83-2.61 4-.65 6.36 1.29 1.58 7.4 3.82 12.44.94a5.29 5.29 0 01-1.59 1.34 11.67 11.67 0 01-10.55 0 3.85 3.85 0 01-2.18-2.84z"
          />
          <path
            fill="#7e587b"
            d="M299.48 292.27c-.34.76.3 1.78 1.43 2.27s2.31.28 2.64-.48-.31-1.78-1.43-2.27-2.31-.28-2.64.48zM303.8 295.37a.87.87 0 00.56 1.1.89.89 0 001.19-.33 1 1 0 00-1.75-.77z"
            opacity=".5"
            style={{ mixBlendMode: "lighten" }}
          />
          <path
            fill="#312a53"
            d="M304.67 301.1a11.67 11.67 0 01-10.55 0 5.36 5.36 0 01-1.58-1.34 11.62 11.62 0 0013.72 0 5.29 5.29 0 01-1.59 1.34z"
          />
          <path
            fill="#624b7c"
            d="M320.73 316.4a23.65 23.65 0 0147.29 0v1.32c-.27 3.27-2.58 6.49-6.92 9-9.23 5.33-24.22 5.33-33.47 0-4.32-2.51-6.63-5.73-6.9-9v-.66c0-.23-.02-.43 0-.66z"
          />
          <path
            fill="#855c75"
            d="M334.11 295.76A23.63 23.63 0 01368 316.4v1.32c-.27 3.27-2.58 6.49-6.92 9a31.44 31.44 0 01-11.87 3.7c6.14-1.06 10.11-7.91 8.22-15.47-2.17-8.78-11.04-21.46-23.32-19.19z"
          />
          <path
            fill="#5a477d"
            d="M320.73 317.72v-.66-.66a23.56 23.56 0 018-17.09c-2.57 2.64-8.29 12.6-2.07 20.19 4.08 5 23.47 12.11 39.42 3a16.48 16.48 0 01-5 4.26c-9.23 5.33-24.22 5.33-33.47 0-4.3-2.55-6.61-5.76-6.88-9.04z"
          />
          <path
            fill="#a1727c"
            d="M344.61 298.73c-1.05 2.41 1 5.63 4.55 7.2s7.32.88 8.37-1.53-1-5.63-4.54-7.2-7.32-.88-8.38 1.53zM358.31 308.54a3.11 3.11 0 103.79-1.05 2.81 2.81 0 00-3.79 1.05z"
            opacity=".5"
            style={{ mixBlendMode: "lighten" }}
          />
          <path
            fill="#4f3c68"
            d="M361.1 326.72c-9.23 5.33-24.22 5.33-33.47 0a16.53 16.53 0 01-5-4.26c.06.06 7.35 7.13 21.7 7.13s21.78-7.13 21.78-7.13a16.48 16.48 0 01-5.01 4.26z"
          />
          <path
            fill="#5b497e"
            d="M313.38 333.84a17.78 17.78 0 0135.54 0 4 4 0 010 .49 4.18 4.18 0 010 .5c-.2 2.46-1.93 4.87-5.2 6.76-6.93 4-18.2 4-25.15 0-3.25-1.89-5-4.3-5.19-6.76v-.5c0-.17-.01-.33 0-.49z"
          />
          <path
            fill="#6a507e"
            d="M323.44 318.32a17.76 17.76 0 0125.48 15.52 4 4 0 010 .49 4.18 4.18 0 010 .5c-.2 2.46-1.93 4.87-5.2 6.76a23.65 23.65 0 01-8.92 2.79c4.61-.8 7.6-5.95 6.18-11.63-1.64-6.6-8.31-16.13-17.54-14.43z"
          />
          <path
            fill="#4f4176"
            d="M313.38 334.83v-.5-.49a17.72 17.72 0 016-12.85c-1.93 2-6.23 9.47-1.56 15.18 3.07 3.75 17.64 9.1 29.63 2.22a12.21 12.21 0 01-3.77 3.2c-6.93 4-18.2 4-25.15 0-3.21-1.89-4.95-4.3-5.15-6.76z"
          />
          <path
            fill="#7e587b"
            d="M331.33 320.56c-.79 1.81.74 4.23 3.42 5.41s5.5.66 6.29-1.15-.73-4.24-3.41-5.41-5.5-.67-6.3 1.15zM341.63 327.93a2.34 2.34 0 102.85-.79 2.12 2.12 0 00-2.85.79z"
            opacity=".5"
            style={{ mixBlendMode: "lighten" }}
          />
          <path
            fill="#3b335f"
            d="M343.72 341.59c-6.93 4-18.2 4-25.15 0a12.52 12.52 0 01-3.76-3.19s5.52 5.35 16.31 5.35 16.37-5.36 16.37-5.36a12.21 12.21 0 01-3.77 3.2z"
          />
          <path
            fill="#6a4f7c"
            d="M347.73 334.94a22.06 22.06 0 0144.1 0v1.23c-.25 3.05-2.4 6.05-6.45 8.39-8.61 5-22.59 5-31.22 0-4-2.34-6.18-5.34-6.43-8.39v-.62c0-.21-.02-.4 0-.61z"
          />
          <path
            fill="#996664"
            d="M360.21 315.68a22 22 0 0131.62 19.26v1.23c-.25 3.05-2.4 6.05-6.45 8.39a29.35 29.35 0 01-11.07 3.44c5.72-1 9.43-7.37 7.67-14.43-2.04-8.17-10.32-20-21.77-17.89z"
            style={{ mixBlendMode: "lighten" }}
          />
          <path
            fill="#5a477d"
            d="M347.73 336.17v-.62-.61a21.94 21.94 0 017.48-15.94c-2.39 2.47-7.72 11.76-1.93 18.84 3.81 4.65 21.9 11.3 36.78 2.76a15.41 15.41 0 01-4.68 4c-8.61 5-22.59 5-31.22 0-4.03-2.38-6.16-5.38-6.43-8.43z"
          />
          <path
            fill="#cca084"
            d="M370 318.45c-1 2.25.91 5.26 4.24 6.72s6.83.82 7.81-1.43-.9-5.25-4.23-6.71-6.82-.83-7.82 1.42zM382.78 327.61a2.9 2.9 0 103.53-1 2.63 2.63 0 00-3.53 1z"
            opacity=".5"
            style={{ mixBlendMode: "lighten" }}
          />
          <path
            fill="#443b6c"
            d="M385.38 344.56c-8.61 5-22.59 5-31.22 0a15.31 15.31 0 01-4.66-4c.05.05 6.85 6.65 20.24 6.65s20.32-6.65 20.32-6.65a15.41 15.41 0 01-4.68 4z"
          />
          <path
            fill="#73537b"
            d="M462.08 345.79a20.14 20.14 0 00-40.26 0 5.33 5.33 0 000 .56 5.43 5.43 0 000 .57c.23 2.78 2.19 5.52 5.89 7.66 7.86 4.54 20.62 4.54 28.5 0 3.68-2.14 5.65-4.88 5.87-7.66a5.43 5.43 0 000-.57 5.33 5.33 0 000-.56z"
          />
          <path
            fill="#996664"
            d="M450.69 328.22a20.1 20.1 0 00-28.87 17.57 5.33 5.33 0 000 .56 5.43 5.43 0 000 .57c.23 2.78 2.19 5.52 5.89 7.66a26.66 26.66 0 0010.11 3.15c-5.23-.9-8.61-6.73-7-13.17 1.86-7.48 9.41-18.28 19.87-16.34z"
            style={{ mixBlendMode: "lighten" }}
          />
          <path
            fill="#624b7d"
            d="M462.08 346.92a5.43 5.43 0 000-.57 5.33 5.33 0 000-.56 20 20 0 00-6.83-14.55c2.19 2.25 7.05 10.73 1.76 17.19-3.47 4.25-20 10.32-33.57 2.52a13.87 13.87 0 004.27 3.63c7.86 4.54 20.62 4.54 28.5 0 3.68-2.14 5.65-4.88 5.87-7.66z"
          />
          <path
            fill="#cca084"
            d="M441.75 330.74c.9 2.06-.83 4.8-3.87 6.14s-6.23.75-7.14-1.3.84-4.8 3.87-6.14 6.23-.75 7.14 1.3zM430.08 339.1a2.65 2.65 0 11-3.22-.89 2.39 2.39 0 013.22.89z"
            opacity=".5"
            style={{ mixBlendMode: "lighten" }}
          />
          <path
            fill="#4f3c68"
            d="M427.71 354.58c7.86 4.54 20.62 4.54 28.5 0a14 14 0 004.25-3.62S454.21 357 442 357s-18.56-6-18.56-6a13.87 13.87 0 004.27 3.58z"
          />
          <path
            fill="#73537b"
            d="M378 348a19.15 19.15 0 0138.29 0 4.87 4.87 0 010 .54 4.7 4.7 0 010 .53c-.21 2.65-2.08 5.25-5.6 7.29-7.47 4.32-19.61 4.32-27.1 0-3.51-2-5.37-4.64-5.59-7.29a4.7 4.7 0 010-.53 4.87 4.87 0 010-.54z"
          />
          <path
            fill="#996664"
            d="M388.83 331.26A19.13 19.13 0 01416.28 348a4.87 4.87 0 010 .54 4.7 4.7 0 010 .53c-.21 2.65-2.08 5.25-5.6 7.29a25.57 25.57 0 01-9.61 3c5-.86 8.19-6.41 6.66-12.53-1.73-7.13-8.96-17.4-18.9-15.57z"
            style={{ mixBlendMode: "lighten" }}
          />
          <path
            fill="#624b7d"
            d="M378 349.05a4.7 4.7 0 010-.53 4.87 4.87 0 010-.54 19.05 19.05 0 016.5-13.84c-2.08 2.14-6.71 10.2-1.68 16.35 3.31 4 19 9.81 31.93 2.4a13.25 13.25 0 01-4.06 3.45c-7.47 4.32-19.61 4.32-27.1 0-3.52-2.04-5.38-4.64-5.59-7.29z"
          />
          <path
            fill="#cca084"
            d="M397.33 333.67c-.86 1.95.79 4.56 3.68 5.83s5.93.72 6.79-1.24-.79-4.56-3.68-5.83-5.93-.71-6.79 1.24zM408.43 341.62a2.51 2.51 0 103.06-.85 2.27 2.27 0 00-3.06.85z"
            opacity=".5"
            style={{ mixBlendMode: "lighten" }}
          />
          <path
            fill="#4f3c68"
            d="M410.68 356.34c-7.47 4.32-19.61 4.32-27.1 0a13.32 13.32 0 01-4.05-3.45c0 .05 6 5.77 17.57 5.77s17.64-5.77 17.64-5.77a13.25 13.25 0 01-4.06 3.45z"
          />
          <path
            fill="#73537b"
            d="M427.68 354.68a7.8 7.8 0 00-15.6 0 1.48 1.48 0 000 .21 1.55 1.55 0 000 .22 4 4 0 002.28 3 12.2 12.2 0 0011 0 4 4 0 002.28-3v-.43z"
          />
          <path
            fill="#885d7a"
            d="M423.26 347.87a7.79 7.79 0 00-11.18 6.81 1.48 1.48 0 000 .21 1.55 1.55 0 000 .22 4 4 0 002.28 3 10.42 10.42 0 003.92 1.22c-2-.35-3.34-2.61-2.71-5.1.72-2.93 3.64-7.11 7.69-6.36z"
          />
          <path
            fill="#624b7d"
            d="M427.68 355.11v-.43A7.79 7.79 0 00425 349c.85.87 2.73 4.16.68 6.66-1.34 1.65-7.74 4-13 1a5.58 5.58 0 001.65 1.4 12.2 12.2 0 0011 0 4 4 0 002.35-2.95z"
          />
          <path
            fill="#9b6779"
            d="M419.8 348.85c.35.79-.32 1.86-1.5 2.37s-2.41.3-2.76-.5.32-1.86 1.5-2.37 2.41-.3 2.76.5zM415.28 352.09a1 1 0 11-1.25-.35.92.92 0 011.25.35z"
            opacity=".5"
            style={{ mixBlendMode: "lighten" }}
          />
          <path
            fill="#4f3c68"
            d="M414.36 358.08a12.2 12.2 0 0011 0 5.44 5.44 0 001.65-1.4 10.59 10.59 0 01-7.16 2.35 10.7 10.7 0 01-7.18-2.35 5.58 5.58 0 001.69 1.4z"
          />
          <path
            fill="#4b3d6e"
            d="M280.35 327.22a7.8 7.8 0 0115.59 0 1.7 1.7 0 010 .22 1.62 1.62 0 010 .22 4 4 0 01-2.28 3 12.2 12.2 0 01-11 0 4 4 0 01-2.27-3 1.62 1.62 0 010-.22 1.7 1.7 0 01-.04-.22z"
          />
          <path
            fill="#53447c"
            d="M284.76 320.42a7.71 7.71 0 013.39-.78 7.79 7.79 0 017.79 7.58 1.7 1.7 0 010 .22 1.62 1.62 0 010 .22 4 4 0 01-2.28 3 10.37 10.37 0 01-3.91 1.22c2-.35 3.33-2.61 2.71-5.11-.72-2.92-3.65-7.1-7.7-6.35z"
          />
          <path
            fill="#3b335f"
            d="M280.35 327.66a1.62 1.62 0 010-.22 1.7 1.7 0 010-.22 7.74 7.74 0 012.65-5.63c-.85.87-2.74 4.15-.69 6.66 1.35 1.64 7.74 4 13 1a5.51 5.51 0 01-1.66 1.41 12.2 12.2 0 01-11 0 4 4 0 01-2.3-3z"
          />
          <path
            fill="#7e587b"
            d="M288.22 321.4c-.34.79.33 1.85 1.5 2.37s2.42.29 2.77-.5-.33-1.86-1.5-2.38-2.42-.29-2.77.51zM292.74 324.63a1 1 0 001.84.81 1 1 0 00-1.84-.81z"
            opacity=".5"
            style={{ mixBlendMode: "lighten" }}
          />
          <path
            fill="#312a53"
            d="M293.66 330.63a12.2 12.2 0 01-11 0 5.41 5.41 0 01-1.64-1.41s2.42 2.35 7.15 2.35a10.71 10.71 0 007.19-2.35 5.51 5.51 0 01-1.7 1.41z"
          />
          <path
            fill="#53447c"
            d="M305 300.28a4.51 4.51 0 019 0 .85.85 0 010 .26 2.32 2.32 0 01-1.31 1.71 7 7 0 01-6.38 0 2.31 2.31 0 01-1.32-1.71v-.26z"
          />
          <path
            fill="#6a507e"
            d="M307.57 296.35a4.62 4.62 0 012-.45 4.5 4.5 0 014.5 4.38.85.85 0 010 .26 2.32 2.32 0 01-1.31 1.71 6.11 6.11 0 01-2.27.71A2.28 2.28 0 00312 300c-.4-1.66-2.09-4.08-4.43-3.65z"
          />
          <path
            fill="#4b3d6e"
            d="M305 300.54v-.26a4.5 4.5 0 011.53-3.25c-.49.5-1.57 2.4-.39 3.85.78.95 4.47 2.3 7.51.56a3.24 3.24 0 01-.95.81 7 7 0 01-6.38 0 2.31 2.31 0 01-1.32-1.71z"
          />
          <path
            fill="#7e587b"
            d="M309.58 296.92c-.21.46.18 1.07.86 1.37s1.4.17 1.6-.29-.19-1.08-.87-1.37-1.39-.17-1.59.29zM312.19 298.79a.52.52 0 00.34.66.48.48 0 10.38-.86.53.53 0 00-.72.2z"
            opacity=".5"
            style={{ mixBlendMode: "lighten" }}
          />
          <path
            fill="#3b335f"
            d="M312.72 302.25a7 7 0 01-6.38 0 3.11 3.11 0 01-.95-.81 7 7 0 008.28 0 3.24 3.24 0 01-.95.81z"
          />
          <path
            fill="#53447c"
            d="M497.13 293a4.51 4.51 0 019 0 .62.62 0 010 .13.49.49 0 010 .12 2.32 2.32 0 01-1.31 1.72 7 7 0 01-6.38 0 2.31 2.31 0 01-1.32-1.72V293z"
          />
          <path
            fill="#6a507e"
            d="M499.68 289a4.5 4.5 0 016.46 3.93.62.62 0 010 .13.49.49 0 010 .12 2.32 2.32 0 01-1.31 1.72 6.11 6.11 0 01-2.27.71 2.28 2.28 0 001.57-2.95c-.42-1.66-2.13-4.07-4.45-3.66z"
          />
          <path
            fill="#4b3d6e"
            d="M497.13 293.2v-.2a4.49 4.49 0 011.53-3.25c-.49.5-1.58 2.4-.39 3.84.78 1 4.47 2.31 7.51.57a3.24 3.24 0 01-.95.81 7 7 0 01-6.38 0 2.31 2.31 0 01-1.32-1.77z"
          />
          <path
            fill="#7e587b"
            d="M501.68 289.59c-.2.46.19 1.07.87 1.37s1.39.17 1.6-.29-.19-1.08-.87-1.38-1.39-.16-1.6.3zM504.3 291.46a.52.52 0 00.34.66.48.48 0 10.38-.86.53.53 0 00-.72.2z"
            opacity=".5"
            style={{ mixBlendMode: "lighten" }}
          />
          <path
            fill="#3b335f"
            d="M504.83 294.92a7 7 0 01-6.38 0 3.11 3.11 0 01-.95-.81 6.08 6.08 0 004.13 1.36 6.17 6.17 0 004.15-1.36 3.24 3.24 0 01-.95.81z"
          />
        </g>
      </svg>
    </div>
    <svg
      className="absolute left-0 bottom-0 w-full h-full"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 662.41 385.07"
      preserveAspectRatio="xMinYMax slice"
    >
      <defs />
      <defs>
        <radialGradient
          id="a"
          cx="368.69"
          cy="333.92"
          r="259.84"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#d37575" />
          <stop offset=".99" stopColor="#58448b" stopOpacity={0} />
        </radialGradient>
        <radialGradient
          id="c"
          cx="2758.74"
          cy="334.46"
          r="259.84"
          gradientTransform="matrix(-1 0 0 1 3280.52 0)"
          xlinkHref="#a"
        />
        <clipPath id="b">
          <path fill="none" d="M0 0h662.41v385.07H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#b)" style={{ clipPath: "url(#clip-path)" }}>
        <g opacity=".2">
          <path
            fill="#58448b"
            d="M361.11 385.53a16.81 16.81 0 00-23.11-4.32v-.59a38.54 38.54 0 00-58.5-33 26.74 26.74 0 00-45.43-12.15A16.77 16.77 0 00223 331a37.75 37.75 0 00-56.86-44.81 26.64 26.64 0 00-13.06-9.39 38.89 38.89 0 00-28.59-65.28 40.12 40.12 0 00-5.63.4 40 40 0 00-69.66-35.4A26.71 26.71 0 0015.34 169c0-.49.05-1 .05-1.45A45.51 45.51 0 00-30.13 122a45.35 45.35 0 00-20.78 5 26.93 26.93 0 002.06-10.3A26.79 26.79 0 00-75.63 90a26.37 26.37 0 00-13.09 3.43v301.82h449.83z"
          />
          <path
            fill="#48376d"
            d="M222.53 395.25h-45.64a22.82 22.82 0 1145.64 0zM323 395.25h-66.4a15.71 15.71 0 1128.31-9.73 22.8 22.8 0 0138.09 9.73zM134.15 360.36a27.23 27.23 0 00-12.43 3 18.08 18.08 0 00-31.4-17.06A18.75 18.75 0 0061 331.06a30.41 30.41 0 00.5-5.35A29 29 0 007.49 311a5.85 5.85 0 000-.6A18.77 18.77 0 00-10 291.66 22.83 22.83 0 005.65 270a22.83 22.83 0 00-22.83-22.82 22.72 22.72 0 00-10 2.34 31.25 31.25 0 002.27-11.63 31.25 31.25 0 00-31.25-31.25 31.15 31.15 0 00-16.66 4.84 18.09 18.09 0 00-11.24-6.71 22.75 22.75 0 006.86-16.29 22.78 22.78 0 00-11.5-19.8v226.57h249a26.5 26.5 0 001.1-7.65 27.24 27.24 0 00-27.25-27.24z"
          />
          <path
            fill="#3e3063"
            d="M80.62 395.25H23.39a29.79 29.79 0 0157.23 0zM7.43 381.31a39.28 39.28 0 01-2.54 13.94h-93.61V271.32a43 43 0 0134 42.07 42.63 42.63 0 01-2.59 14.74h.08a16.78 16.78 0 0116.63 14.69 39.72 39.72 0 018.58-.94 39.44 39.44 0 0139.45 39.43z"
          />
          <circle
            cx="101.05"
            cy="240.18"
            r="12.28"
            fill="#48376d"
            transform="rotate(-45 101.048 240.185)"
          />
          <circle cx="86.62" cy="303.13" r="8.6" fill="#48376d" />
          <path
            fill="#48376d"
            d="M136.23 339a5.75 5.75 0 115.75 5.75 5.75 5.75 0 01-5.75-5.75zM239 373.74a5.74 5.74 0 115.74 5.75 5.74 5.74 0 01-5.74-5.75zM6.61 287.71a5.75 5.75 0 115.74 5.75 5.74 5.74 0 01-5.74-5.75zM124.5 248.67a5.87 5.87 0 115.87 5.87 5.87 5.87 0 01-5.87-5.87z"
          />
          <path
            fill="#58448b"
            d="M241.25 311a5.55 5.55 0 115.55 5.55 5.55 5.55 0 01-5.55-5.55z"
          />
          <circle
            cx="181.19"
            cy="251.6"
            r="9.63"
            fill="#58448b"
            transform="rotate(-25.44 181.23 251.633)"
          />
          <path
            fill="#3e3063"
            d="M19.23 365.39a4.85 4.85 0 114.85 4.85 4.85 4.85 0 01-4.85-4.85z"
          />
          <circle
            cx="95.21"
            cy="370.24"
            r="9.53"
            fill="#3e3063"
            transform="rotate(-76.66 95.223 370.242)"
          />
          <path
            fill="#ad6475"
            d="M272.13 353.47a38.5 38.5 0 017.41-5.85 26.68 26.68 0 00-33.08-19.53 26.74 26.74 0 0125.67 25.38zM217.33 316.32a37.6 37.6 0 01-4.54 18 16.72 16.72 0 019.94-3.27h.27a37.75 37.75 0 00-35-51.75h-.81a37.76 37.76 0 0130.14 37.02zM206.78 342.49a37.63 37.63 0 01-26.39 11.56 37.85 37.85 0 0025.67-3.86 16 16 0 01-.18-2.31 16.61 16.61 0 01.9-5.39z"
          />
          <path
            fill="#ad6475"
            d="M223 331h-.29a16.72 16.72 0 00-9.94 3.27 37.9 37.9 0 01-6 8.19 16.61 16.61 0 00-.9 5.39 16 16 0 00.18 2.31A37.88 37.88 0 00223 331zM138.3 276.3a26.39 26.39 0 016.45-.82 26.8 26.8 0 018.35 1.34 38.89 38.89 0 00-9.24-60.11 38.91 38.91 0 01-5.56 59.59zM105.79 205.87v.88a38.75 38.75 0 0110.56-1.46c1.23 0 2.45.06 3.65.17.11-1.16.17-2.34.17-3.53A40 40 0 0062.52 166c1.08-.09 2.16-.14 3.26-.14a40 40 0 0140.01 40.01z"
          />
          <path
            fill="#ad6475"
            d="M105.77 206.75a40 40 0 01-1.65 10.56 38.73 38.73 0 0114.76-5.31 39.62 39.62 0 001.1-6.49c-1.2-.11-2.42-.17-3.65-.17a38.75 38.75 0 00-10.56 1.41z"
          />
          <path
            fill="url(#a)"
            d="M361.11 385.53a16.81 16.81 0 00-23.11-4.32v-.59a38.54 38.54 0 00-58.5-33 26.74 26.74 0 00-45.43-12.15A16.77 16.77 0 00223 331a37.75 37.75 0 00-56.86-44.81 26.64 26.64 0 00-13.06-9.39 38.89 38.89 0 00-28.59-65.28 40.12 40.12 0 00-5.63.4 40 40 0 00-69.66-35.4A26.71 26.71 0 0015.34 169c0-.49.05-1 .05-1.45A45.51 45.51 0 00-30.13 122a45.35 45.35 0 00-20.78 5 26.93 26.93 0 002.06-10.3A26.79 26.79 0 00-75.63 90a26.37 26.37 0 00-13.09 3.43v301.82h449.83z"
            style={{ mixBlendMode: "lighten" }}
          />
        </g>
        <g opacity=".2">
          <path
            fill="#58448b"
            d="M529.35 386.08a16.8 16.8 0 0123.07-4.33v-.59a38.55 38.55 0 0158.51-33A26.74 26.74 0 01656.34 336a16.77 16.77 0 0111.1-4.45 37.76 37.76 0 0156.86-44.81 26.7 26.7 0 0113.06-9.39 38.9 38.9 0 0134.22-64.87 40 40 0 0169.66-35.41 26.71 26.71 0 0133.88-7.58v-1.45a45.52 45.52 0 0166.3-40.48 26.78 26.78 0 0124.67-37.06 26.37 26.37 0 0113.09 3.43v301.86H529.35z"
          />
          <path
            fill="#48376d"
            d="M567.43 395.79h66.4a15.7 15.7 0 10-28.31-9.73 22.8 22.8 0 00-38.09 9.73zM651.46 374.29a5.74 5.74 0 10-5.74 5.74 5.74 5.74 0 005.74-5.74z"
          />
          <circle cx="643.66" cy="311.51" r="5.55" fill="#58448b" />
          <path
            fill="#ad6475"
            d="M618.33 354a38.58 38.58 0 00-7.41-5.85 26.76 26.76 0 0126-20.5 27.05 27.05 0 017.07 1A26.75 26.75 0 00618.33 354z"
          />
          <path
            fill="url(#c)"
            d="M529.35 386.08a16.8 16.8 0 0123.07-4.33v-.59a38.55 38.55 0 0158.51-33A26.74 26.74 0 01656.34 336a16.77 16.77 0 0111.1-4.45 37.76 37.76 0 0156.86-44.81 26.7 26.7 0 0113.06-9.39 38.9 38.9 0 0134.22-64.87 40 40 0 0169.66-35.41 26.71 26.71 0 0133.88-7.58v-1.45a45.52 45.52 0 0166.3-40.48 26.78 26.78 0 0124.67-37.06 26.37 26.37 0 0113.09 3.43v301.86H529.35z"
            style={{ mixBlendMode: "lighten" }}
          />
        </g>
      </g>
    </svg>
  </div>
</>

        <div className="px-6 py-20 " style={{backgroundImage:`url(${bg})`}}>
          <div className="container flex justify-between mx-auto">
            <div className="w-full  lg:w-8/12">
              <div className="flex items-center justify-between">
                <h1 className="text-xl font-bold text-gray-700 md:text-2xl">Courses</h1>
                <div>
                 
                </div>
              </div>
              <div className="mt-6">
                <div className="max-w-4xl px-10 py-6 mx-auto bg-white rounded-lg shadow-md">
                  <div className="flex items-center justify-between">
                    <span className="font-light text-gray-600">Start Date: Jun 1, 2020</span>
                   <div className="flex">
                   <p
                      className="mx-2 px-2 py-1 font-bold text-gray-100 bg-gray-600 rounded hover:bg-gray-500"
                    >
                     Level: Advanced
                    </p>
                    <a
                      href="./admission"
                      className="mx-2 px-2 py-1 font-bold text-gray-100 bg-blue-600 rounded hover:bg-gray-500"
                    >
                     Enroll
                    </a>
                   </div>
                  </div>
                  <div className="mt-2">
                    <a
                      href="#"
                      className="text-2xl font-bold text-gray-700 hover:underline"
                    >
                      Ausbildung Course
                    </a>
                    <p className="mt-2 text-gray-600 text-left">
                    <div>
    {/* English Section */}
    <section>
      <h2 className="font-bold text-xl">Start Your Future with an Ausbildung!</h2>
      <p className="font-semibold"> <br></br>
        An <strong>Ausbildung</strong> is not just training – it’s the gateway to a successful career in Germany! By completing this dual program, you gain:
      </p>
      <ul>
        <li><strong>Professional Expertise:</strong> Hands-on experience in your chosen field.</li>
        <li><strong>Financial Stability:</strong> Earn while you learn with a monthly stipend.</li>
        <li><strong>Global Recognition:</strong> Get a certified qualification highly valued worldwide.</li>
        <li><strong>Career Growth:</strong> Open doors to job opportunities in top German industries.</li>
      </ul>
      <h3>Why Choose Aim Genius?</h3>
      <p>
        At Aim Genius, we simplify your journey by:
      </p>
      <ul>
        <li>Helping you choose the right Ausbildung program for your goals.</li>
        <li>Guiding you through the application process, from start to finish.</li>
        <li>Supporting you with language skills and cultural adaptation for life in Germany.</li>
      </ul>
      <p>Take the first step toward building a promising future with Aim Genius by your side!</p>
    </section>
<Divider></Divider>
    {/* Bangla Section */}
    <section>
      <h2 className="font-bold text-xl">Ausbildung: আপনার ভবিষ্যৎ গড়ার পথ!</h2>
      <p className="font-semibold"> <br></br>
        একটি <strong>Ausbildung</strong> কেবল একটি প্রশিক্ষণ নয় – এটি জার্মানিতে একটি সফল ক্যারিয়ারের দরজা খুলে দেয়! এই দ্বৈত প্রোগ্রাম সম্পন্ন করার মাধ্যমে আপনি অর্জন করবেন:
      </p>
      <ul>
        <li><strong>পেশাগত দক্ষতা:</strong> আপনার পছন্দের ক্ষেত্রে বাস্তব অভিজ্ঞতা।</li>
        <li><strong>আর্থিক স্থিতিশীলতা:</strong> প্রশিক্ষণের সময় প্রতি মাসে বৃত্তি।</li>
        <li><strong>বিশ্বব্যাপী স্বীকৃতি:</strong> আন্তর্জাতিকভাবে স্বীকৃত একটি যোগ্যতা।</li>
        <li><strong>ক্যারিয়ার বৃদ্ধি:</strong> জার্মানির শীর্ষ শিল্পে কাজের সুযোগ।</li>
      </ul>
      <h3>Aim Genius কেন বেছে নেবেন?</h3>
      <p>
        আমরা Aim Genius-এ আপনার যাত্রাকে সহজ করি:
      </p>
      <ul>
        <li>আপনার লক্ষ্য অনুযায়ী সঠিক Ausbildung প্রোগ্রাম বেছে নিতে সাহায্য করি।</li>
        <li>আবেদন প্রক্রিয়া থেকে শুরু করে সফল সমাপ্তি পর্যন্ত গাইড করি।</li>
        <li>ভাষা দক্ষতা এবং জার্মান সংস্কৃতিতে অভ্যস্ত হতে আপনাকে প্রস্তুত করি।</li>
      </ul>
      <p>Aim Genius-এর সঙ্গে আপনার উজ্জ্বল ভবিষ্যতের দিকে প্রথম পদক্ষেপ নিন!</p>
    </section>
  </div>
                     </p>
                  </div>
                
                </div>
              </div>
            
             
            </div>
            <div className="hidden w-4/12 -mx-8 lg:block">
              <div className="px-8">
                <h1 className="mb-4 text-xl font-bold text-gray-700">Batches</h1>
                <div className="flex flex-col max-w-sm px-6 py-4 mx-auto bg-white rounded-lg shadow-md">
                  <ul className="-mx-4">
                    <li className="p-4 flex items-center">
                    
                      <p>
                        <a
                          href="#"
                          className="mx-1 font-bold text-gray-700 hover:underline"
                        >
                          A1 Batch
                        </a>
                        <span className="text-sm font-light text-gray-700">
                          Basics of German
                        </span>
                      </p>
                    </li>
                    <li className="p-4 flex items-center mt-6">
                    
                      <p>
                        <a
                          href="#"
                          className="mx-1 font-bold text-gray-700 hover:underline"
                        >
                          A2 Batch
                        </a>
                        <span className="text-sm font-light text-gray-700">
                          Fluency in German
                        </span>
                      </p>
                    </li>
                    <li className="p-4 flex items-center mt-6">
                      
                      <p>
                        <a
                          href="#"
                          className="mx-1 font-bold text-gray-700 hover:underline"
                        >
                          B2 Batch
                        </a>
                        <span className="text-sm font-light text-gray-700">
                         Advanced German
                        </span>
                      </p>
                    </li>
                
                  </ul>
                </div>
              </div>
             
              <div className="px-8 mt-10">
                <h1 className="mb-4 text-xl font-bold text-gray-700">Resources</h1>
                <div className="flex flex-col max-w-sm px-8 py-6 mx-auto bg-white rounded-lg shadow-md">
                <div className="flex items-center justify-center">
                    <a
                      href="#"
                      className="px-2 py-1 text-sm text-green-100 bg-gray-600 rounded hover:bg-gray-500"
                    >
                      Quiz
                    </a>
                  </div>
                  <div className="mt-4">
                    <a
                      href="#"
                      className="text-lg font-medium text-gray-700 hover:underline"
                    >
                      Self placed Courses / Practices
                    </a>
                  </div>
                  

                  <Divider></Divider>

                  <div className="flex items-center justify-center">
                    <a
                      href="#"
                      className="px-2 py-1 text-sm text-green-100 bg-gray-600 rounded hover:bg-gray-500"
                    >
                      PDF
                    </a>
                  </div>
                  <div className="mt-4">
                    <a
                      href="#"
                      className="text-lg font-medium text-gray-700 hover:underline"
                    >
                      Digital Resources for preparation and home study
                    </a>
                  </div>
                 
                </div>
                
              </div>
            </div>
          </div>
        </div>
       
      </div>
      )
}

export default Homepage